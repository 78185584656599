import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import Button from "../../../components/Button";
import InputText from "../../../components/InputText";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const DiagnosticParentPage = () => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate()

  return (
    <Box>
      <Flex position={"apart"}>
        <InputText
            placeholder={"Rechercher un ecole"}
            onChange={(value) => setQuery(value.toLowerCase())}
            value={query}
          />
          <Button
            onClick={() => {
              navigate("add")
            }}
          >
            Ajouter
          </Button>
      </Flex>
    </Box>
  );
};

export default DiagnosticParentPage;
