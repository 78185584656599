import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const diagnosticParentApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getDiagnosticParents: build.query({
      query: () => API.DIAGNOSTICS_PARENT.GETALL(),
      providesTags: ["DiagnosticParents"],
    }),
    addDiagnosticParents: build.mutation({
      query({ body, token }) {
        return {
          url: API.DIAGNOSTICS_PARENT.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["DiagnosticParents"],
    }),
    EditDiagnosticParents: build.mutation({
      query({ body, token }) {
        return {
          url: API.DIAGNOSTICS_PARENT.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["DiagnosticParents"],
    }),
    deleteDiagnosticParents: build.mutation({
      query(id) {
        return {
          url: API.DIAGNOSTICS_PARENT.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["DiagnosticParents"],
    }),
  }),
});

export const {
  useGetDiagnosticParentsQuery,
  useAddDiagnosticParentsMutation,
  useEditDiagnosticParentsMutation,
  useDeleteDiagnosticParentsMutation,
} = diagnosticParentApi;
