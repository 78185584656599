function EditAction(mutationName, data, token) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await mutationName({ body: data, token });
      return resolve(res)
    } catch (error) {
      reject(error)
    }
  })
}

export default EditAction;
