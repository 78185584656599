import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { ADMIN_HOME } from "../../utils/navigationPaths";
import { useLoginUserMutation } from "../../redux/features/userApi";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../redux/reducers/userSlice";
import useCurrentUser from "../../hooks/getCurrentUser";
import toast from "react-hot-toast";
import { Button } from "@mantine/core";

const LoginPage = () => {
  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const isConnected = useCurrentUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginUser] = useLoginUserMutation();
  const [isLoading, setIsLoading] = useState(false);

  const checkIfFormIsValid = async (data) => {
    setIsLoading(true);
    if (!data.phone) {
      setError("phone", { type: "custom", message: "Telephone non valide" });
      return;
    }
    if (!data.password) {
      setError("password", {
        type: "custom",
        message: "Mot de passe non valide",
      });
      return;
    }
    try {
      const res = await loginUser({ body: data }).unwrap();
      // console.log({ res });
      if (res?.status === "ADMIN") {
        console.log("ADMIN");
        const { payload } = dispatch(setCurrentUser(res));
        if (payload.token.token) {
          navigate(ADMIN_HOME, { replace: true });
        }
      } else if (res?.status === "PARTENAIRE") {
        const { payload } = dispatch(setCurrentUser(res));
        if (payload.token.token) {
          console.log("PARTENAIRE");
          // console.log({ payload });
          return navigate("/partenaire", { replace: true });
        }
      } else {
        return toast.error("Vous ne pouvez pas acceder a cette plateform");
      }
    } catch (error) {
      // setError("password", {
      //   type: "custom",
      //   message: "Telephone ou mot de passe incorrecte",
      // });
      toast.error("Telephone ou mot de passe incorrecte");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="vh-100 d-flex align-items-center">
      <form
        onSubmit={handleSubmit(checkIfFormIsValid)}
        style={{ maxWidth: "600px", margin: "auto", flex: "1" }}
        className="shadow p-4"
      >
        <h2
          className="text-center"
          style={{ marginBottom: 20, fontWeight: "bold" }}
        >
          BackOffice Genimi
        </h2>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Telephone
          </label>
          <input
            {...register("phone", { minLength: 9 })}
            type="tel"
            className="form-control"
            id="phone"
            aria-describedby="phoneHelp"
          />
          {errors.phone && <p className="text-error">{errors.phone.message}</p>}
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Mot de passe
          </label>
          <input
            {...register("password", { minLength: 4 })}
            type="password"
            className="form-control"
            id="password"
          />
          {errors.password && (
            <p className="text-error">{errors.password.message}</p>
          )}
        </div>
        <Button
          type="submit"
          // className="btn btn-primary w-100"
          loading={isLoading}
          mt={15}
          w={"100%"}
        >
          {isLoading ? "Envoi..." : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default LoginPage;
