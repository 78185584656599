import React, { useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { BarChart } from "../../../components/BarChart";
import { Flex, Group, Loader, Select } from "@mantine/core";
import { useGetCoachSessionsQuery } from "../../../redux/features/coachApi";
import { MonthPickerInput } from "@mantine/dates";

Chart.register(CategoryScale);

export const ConnexionKpi = ({ coachId }) => {
  const [filter, setFilter] = useState({});

  const { data: sessions, isLoading: isSessionLoading } =
    useGetCoachSessionsQuery(
      {
        coachId,
        filter: Object.keys(filter)
          .map((key) => key + "=" + filter[key])
          .join("&"),
      },
      {
        filter,
      }
    );

  const chartData = {
    labels: sessions?.map((label) => label.label),
    datasets: [
      {
        label: "Connexion",
        data: sessions?.map((data) => data.count),
        barPercentage: 1,
        minBarLength: 0,
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
      },
    ],
  };
  return (
    <>
      <h4 style={{ textAlign: "center" }}>Statistiques connexion</h4>
      <Flex justify="space-between">
        <Group>
          <Select
            placeholder="Filtrer les connexions"
            data={[
              { value: "weekly", label: "Cette semaine" },
              { value: "monthly", label: "Ce mois" },
              { value: "yearly", label: "Cette annee" },
            ]}
            clearable
            onChange={(e) => {
              setFilter({ filter: e });
            }}
            value={filter?.filter || ""}
          />
        </Group>
        <Group>
          <MonthPickerInput
            label="Choisir une date"
            placeholder="date"
            valueFormat="MMMM YYYY"
            minDate="2022"
            onChange={(e) =>
              setFilter({
                ...filter,
                month: e.getMonth(),
                year: e.getFullYear(),
                filter: "monthly",
              })
            }
            w={200}
          />
        </Group>
      </Flex>
      {isSessionLoading ? <Loader /> : <BarChart chartData={chartData} />}
    </>
  );
};
