import React, { useCallback, useState } from "react";
import Flex from "../../../components/Flex";
import { useGetDiagnostiquesQuery } from "../../../redux/features/diagnostiqueApi";
import Box from "../../../components/Box";
import Text from "../../../components/Text";
// import { Eye, FileText, Pencil, QuestionMark, Trash } from "tabler-icons-react";
// import Badge from "../../../components/Badge";
import Divider from "../../../components/Divider";
import { useGetClassesQuery } from "../../../redux/features/classeApi";
import { useGetMatiereQuery } from "../../../redux/features/matiereApi";
import {
  useAddEpreuvesMutation,
  useArchiveEpreuveMutation,
  useDeleteEpreuvesMutation,
  useEditEpreuvesMutation,
  useGetEpreuvesQuery,
} from "../../../redux/features/epreuveApi";
import ReactQuill from "react-quill";
import {
  Button,
  Grid,
  Group,
  Loader,
  Modal,
  Pagination,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import AddAction from "../actions/AddAction";
import EpreuveTable from "../components/EpreuveTable";
import { useGetNiveauxQuery } from "../../../redux/features/niveauApi";
import DeleteAction from "../actions/DeleteAction";
import { Link } from "react-router-dom";
import { modals } from "@mantine/modals";

function EpreuvePage() {
  const { data, isLoading, refetch } = useGetEpreuvesQuery();
  const [addEpreuves, { isLoading: addLoading }] = useAddEpreuvesMutation();
  const [_, { isLoading: editLoading }] = useEditEpreuvesMutation();
  const [deleteEpreuves] = useDeleteEpreuvesMutation();

  const { data: classes } = useGetClassesQuery();
  const { data: matieres } = useGetMatiereQuery();
  const { data: niveaux } = useGetNiveauxQuery();
  const [archiveEpreuve] = useArchiveEpreuveMutation();
  const [sorted, setSorted] = useState({
    matiere: "",
    classe: "",
    niveau: "",
  });

  // console.log({ data });

  // const { data: classes } = useGetClassesQuery();
  // const { data: niveaux } = useGetNiveauxQuery();
  // const { data: matieres } = useGetMatiereQuery();
  const { data: diagnostiques } = useGetDiagnostiquesQuery();

  const [query, setQuery] = useState("");
  const [itemEdited, setItemEdited] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [openedDetails, setOpenedDetails] = useState(false);

  const [opened, { open, close }] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      diagnosticId: "",
      sujet: "",
      correction: "",
      titre: "",
      bareme: "",
      niveauId: "",
    },

    validate: {
      diagnosticId: (value) => (value ? null : "Ce champ est require"),
      sujet: (value) => (value ? null : "Ce champ est require"),
      correction: (value) => (value ? null : "Ce champ est require"),
      titre: (value) => (value ? null : "Ce champ est require"),
      bareme: (value) => (value ? null : "Ce champ est require"),
      niveauId: (value) => (value ? null : "Ce champ est require"),
    },
  });

  const handleArchiveEpreuve = (id, value) => {
    try {
      const body = {
        id: id,
        archived: !value,
      };
      const res = archiveEpreuve({ body });
      console.log("response archive", res);
    } catch (error) {
      console.log("handleArchiveEpreuve erreur ", error);
    }
  };

  // console.log({ data });

  const openModal = (id) => {
    console.log({ id });
    return modals.openConfirmModal({
      title: "Voulez vouz supprimer cette epreuve ?",
      children: (
        <Text size="sm">
          Pour supprimer cette epreuve, cliquez sur le bouton confiter
        </Text>
      ),
      labels: { confirm: "Confirmer", cancel: "Annuler" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => await DeleteAction(deleteEpreuves, id),
    });
  };

  const filterData = useCallback(
    (item) => {
      const schoolMatch =
        !sorted.matiere || item?.diagnosticId?.matiereId === sorted.matiere;
      const classMatch =
        !sorted.classe || item?.diagnosticId?.classeId === sorted.classe;
      const niveauMatch =
        !sorted.niveau || item?.niveauId?.id === sorted.niveau;

      return schoolMatch && classMatch && niveauMatch;
    },
    [sorted]
  );

  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const totalPages = Math.ceil(data?.data.filter(filterData).length / limit);
  const currentDataPage = data?.data
    ? data?.data
        .filter(filterData)
        .filter((item) => item?.type_diagnostic === "PARENT")
        ?.slice(startIndex, endIndex)
    : [];


  return (
    <div>
      <Flex position={"apart"} mb={20}>
        <TextInput
          placeholder={"Rechercher une epreuve"}
          onChange={(value) => setQuery(value?.target.value.toLowerCase())}
          value={query}
        />
        <Button
          onClick={() => {
            setItemEdited(null);
            // setForm(null);
            open();
          }}
          component={Link}
          to={"parent"}
        >
          Ajouter une Epreuve
        </Button>
      </Flex>

      {/* <Divider my={20} /> */}

      <Grid mb={20}>
        <Grid.Col lg={3} sm={6} xs={12}>
          <Select
            searchable
            clearable
            onChange={(e) => setSorted({ ...sorted, niveau: e })}
            data={
              niveaux?.data.map((item) => ({
                value: item?.id,
                label: item?.name,
              })) ??
              [] ??
              []
            }
            placeholder="Niveau"
          />
        </Grid.Col>
        <Grid.Col lg={3} sm={6} xs={12}>
          <Select
            searchable
            clearable
            data={
              classes?.data.map((item) => ({
                value: item?.id,
                label: item?.name,
              })) ??
              [] ??
              []
            }
            placeholder="Classes"
            onChange={(e) => setSorted({ ...sorted, classe: e })}
          />
        </Grid.Col>
        <Grid.Col lg={3} sm={6} xs={12}>
          <Select
            searchable
            clearable
            onChange={(e) => setSorted({ ...sorted, matiere: e })}
            data={
              matieres?.data.map((item) => ({
                value: item?.id,
                label: item?.name,
              })) ??
              [] ??
              []
            }
            placeholder="Matieres"
          />
        </Grid.Col>
      </Grid>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <EpreuveTable
            data={currentDataPage ?? []}
            handleDelete={(id) => {
              openModal(id);
            }}
            handleArchive={(id, value) => handleArchiveEpreuve(id, value)}
          />
          <Group position="apart" mb={20}>
            <Select
              data={["5", "10", "20", "30", "40", "50", "100"]}
              onChange={setLimit}
              value={limit.toString()}
            />
            <Pagination
              value={currentPage}
              onChange={setCurrentPage}
              total={totalPages}
            />
          </Group>
        </>
      )}

      {/* Details Modal */}
      <Modal
        opened={openedDetails}
        onClose={() => setOpenedDetails(false)}
        title={"Detail " + itemEdited?.titre}
      >
        <Text weight={600} size={17}>
          Sujet
        </Text>
        <Box>
          <div
            style={{
              padding: ".5rem",
              backgroundColor: "rgba(200,200,0,0.1)",
              borderRadius: 7,
            }}
            className=""
            dangerouslySetInnerHTML={{ __html: itemEdited?.sujet }}
          ></div>
        </Box>

        <Divider my={20} />

        <Text weight={600} size={17}>
          Correction
        </Text>
        <Box>
          <div
            className=""
            style={{
              padding: ".5rem",
              backgroundColor: "rgba(0,255,0,0.1)",
              borderRadius: 7,
            }}
            dangerouslySetInnerHTML={{ __html: itemEdited?.correction }}
          ></div>
        </Box>
      </Modal>

      {/* My modal */}
      <Modal
        opened={opened}
        onClose={close}
        title={itemEdited ? "Edit" : "Add epreuve"}
        size={"lg"}
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            // return console.log(values);
            await AddAction(addEpreuves, values);
            close();
            form.reset();
          })}
        >
          <TextInput
            label={"Titre"}
            placeholder={"mettre votre titre"}
            {...form.getInputProps("titre")}
            mb={15}
          />

          <TextInput
            label={"Bareme"}
            placeholder={"mettre votre bareme"}
            type="number"
            {...form.getInputProps("bareme")}
          />

          <Group>
            <Select
              label={"Diagnostique"}
              placeholder="choisir un diagnostic"
              data={
                diagnostiques?.data.map((item) => ({
                  value: item?.id,
                  label: item?.title,
                })) ?? []
              }
              // mt={15}
              {...form.getInputProps("diagnosticId")}
              style={{ flex: 1 }}
            />

            <Select
              mt={15}
              searchable
              clearable
              data={
                niveaux?.data.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })) ??
                [] ??
                []
              }
              label="Niveau"
              placeholder="Niveau"
              {...form.getInputProps("niveauId")}
            />
          </Group>

          <Box mt={15}>
            <Text>Sujet</Text>
            <ReactQuill
              theme="snow"
              placeholder="Mettre icic votre suijet"
              value={form.values.sujet}
              onChange={(e) => form.setFieldValue("sujet", e)}
              style={{ marginTop: 5 }}
            />
          </Box>

          <Box mt={15}>
            <Text>Correction</Text>
            <ReactQuill
              theme="snow"
              placeholder="Mettre icic votre correction"
              value={form.values.correction}
              onChange={(e) => form.setFieldValue("correction", e)}
              style={{ marginTop: 5 }}
            />
          </Box>

          <Divider my={15} />

          <Flex position={"end"} gap={15}>
            <Button color={"gray"} onClick={close}>
              Annuler
            </Button>
            <Button
              type={"submit"}
              // onClick={itemEdited ? handleEdit : handleAdd}
              loading={addLoading || editLoading}
            >
              {itemEdited ? "Modifier" : "Ajouter"}
            </Button>
          </Flex>
        </form>
      </Modal>
    </div>
  );
}

export default EpreuvePage;
