import { useMemo, useState } from "react";
import { Table, ScrollArea, Group, Text, ActionIcon } from "@mantine/core";
import { Edit, School, Trash } from "tabler-icons-react";
import { sortData } from "../../../utils/tableActions";
import Th from "../../../components/Th";

export default function EcoleTable({ data, handleDelete, handleEdit }) {
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search: "" }));
  };

  useMemo(() => {
    setSortedData(data);
    // console.log(data);
  }, [data]);

  const rows = sortedData.map((row) => (
    <tr key={row?.id}>
      <td>
        <Group noWrap>
          <School color="gray" />
          <Text weight={600} style={{ whiteSpace: "nowrap" }}>
            {row?.name}
          </Text>
        </Group>
      </td>
      <td>
        <Text weight={500} size={17}>
          {row?.ville?.name}
        </Text>
      </td>
      <td>
        <Text
          weight={row?.directeur?.id ? 600 : 500}
          color={row?.directeur?.id ? "blue" : "gray"}
          size={17}
        >
          {row?.directeur?.fullName ?? "Pas de directeur"}
        </Text>
      </td>
      <td>
        <Group position="right">
          <ActionIcon color="green" onClick={() => handleEdit(row.id, row)}>
            <Edit size={15} />
          </ActionIcon>
          <ActionIcon onClick={() => handleDelete(row.id)}>
            <Trash size={15} color="red" />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <ScrollArea>
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw={700}
        sx={{ tableLayout: "fixed" }}
        bg={"#fff"}
      >
        <thead>
          <tr>
            <Th
              sorted={sortBy === "fullName"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("fullName")}
            >
              Ecoles
            </Th>
            <Th
              sorted={sortBy === "phone"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("phone")}
            >
              Villes
            </Th>
            <Th
              sorted={sortBy === "phone"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("phone")}
            >
              Directeurs
            </Th>
            <th style={{ textAlign: "right" }}>action</th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              {/* <td colSpan={Object.keys(data[0]).length}> */}
              <Text weight={500} align="center">
                Nothing found
              </Text>
              {/* </td> */}
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}
