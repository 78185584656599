import React, { useState } from "react";
import Flex from "../../components/Flex";
import {
  useAddElevesMutation,
  useDeleteElevesMutation,
  useEditElevesMutation,
  useGetAllElevesQuery,
  useGetElevesQuery,
} from "../../redux/features/eleveApi";
import { useGetNiveauxQuery } from "../../redux/features/niveauApi";
import { useGetParentsQuery } from "../../redux/features/parentApi";
import { useGetEcolesQuery } from "../../redux/features/ecoleApi";
import { useGetCoachsQuery } from "../../redux/features/coachApi";
import { useAddClasseHistoriqueMutation, useGetClassesQuery } from "../../redux/features/classeApi";
import {
  Box,
  Text,
  TextInput,
  Button,
  Loader,
  Space,
  Modal,
  Select,
  Divider,
  MultiSelect,
  Paper,
  Title,
  createStyles,
  rem,
  Grid,
  Pagination,
  Group,
} from "@mantine/core";
import EleveTable from "./components/EleveTable";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import AddAction from "./actions/AddAction";
import EditAction from "./actions/EditAction";
import useGetDebounceData from "../../hooks/useGetDebounceData";
import { useGetParrainsQuery } from "../../redux/features/parrainApi";
import DeleteAction from "./actions/DeleteAction";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import Papa from "papaparse";
import "../../../src/styles.css"

const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    backgroundImage: `linear-gradient(-60deg, ${theme.colors[theme.primaryColor][4]
      } 0%, ${theme.colors[theme.primaryColor][7]} 100%)`,
    padding: `calc(${theme.spacing.xl} * 1.5)`,
    borderRadius: theme.radius.md,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },
  stat: {
    flex: 1,

    "& + &": {
      paddingLeft: theme.spacing.xl,
      marginLeft: theme.spacing.xl,
      borderLeft: `${rem(1)} solid ${theme.colors[theme.primaryColor][3]}`,

      [theme.fn.smallerThan("sm")]: {
        paddingLeft: 0,
        marginLeft: 0,
        borderLeft: 0,
        paddingTop: theme.spacing.xl,
        marginTop: theme.spacing.xl,
        borderTop: `${rem(1)} solid ${theme.colors[theme.primaryColor][3]}`,
      },
    },
  },
  title: {
    color: theme.white,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: theme.fontSizes.sm,
  },

  count: {
    color: theme.white,
    fontSize: rem(32),
    lineHeight: 1,
    fontWeight: 700,
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    fontSize: theme.fontSizes.sm,
    marginTop: rem(5),
  },
}));

function checkTable(array) {
  if (array === undefined || array === null || array.length === 0) {
    return false;
  }
  return true;
}

const ElevePage = () => {
  let [limit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const [data, firstDataLoading, allDataLoading] = useGetDebounceData({
    firstQuery: useGetElevesQuery,
    allQuery: useGetAllElevesQuery,
    limit,
  });

  // const { data: villes } = useGetVillesQuery();
  const { data: elevesData, isLoading } = useGetAllElevesQuery();
  const { data: classes } = useGetClassesQuery();
  const { data: niveaux } = useGetNiveauxQuery();
  const { data: parents } = useGetParentsQuery();
  const { data: parrains } = useGetParrainsQuery();
  const { data: ecoles } = useGetEcolesQuery();
  const { data: coachs } = useGetCoachsQuery();
  const [addEleves, { isLoading: addLoading }] = useAddElevesMutation();
  const [editEleves, { isLoading: editLoading }] = useEditElevesMutation();
  const [deleteEleves] = useDeleteElevesMutation();

  const [opened, { open, close }] = useDisclosure(false);
  const [addClassesHistorique] = useAddClasseHistoriqueMutation();
  const newClasse = { action: "Ajout avec la classe " }

  const [query, setQuery] = useState("");
  const [itemEdited, setItemEdited] = useState(null);

  const { classes: styles } = useStyles();

  const [sorted, setSorted] = useState({
    ecole: "",
    classe: "",
    niveau: "",
    coach: "",
  });

  const form = useForm({
    initialValues: {
      classeId: "",
      schoolId: "",
      abandoned: "NON",
      coachs: [],
      parrentId: "",
      parrainId: "",
      niveaux: [],
    },

    validate: {},
  });

  const [queryParams, setQueryParams] = useSearchParams({ coach: "" });

  // console.log("queryParams: " , queryParams)

  const is_coached = [
    // ce tableau nous permet de filtrer les eleves sans coachs,avec coachs et all
    // je l'ai nomme fullName pour que ca correspond avec l'autre tables de coachs
    {
      id: "all",
      fullName: "Tous les coachs",
    },
    {
      id: "true",
      fullName: "Les éleves coachés",
    },
    {
      id: "false",
      fullName: "Sans coach",
    },
  ];

  // Pagination Configuration
  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const totalPages = data
    ? Math.ceil(
      data
        .filter(
          (item) =>
            item?.firstName.toLowerCase().includes(query) ||
            item?.lastName.toLowerCase().includes(query)
        )
        .filter((item) => {
          const schoolMatch = !sorted.ecole || item.schoolId === sorted.ecole;
          const classMatch =
            !sorted.classe || item.classeId === sorted.classe;

          const coachMatch =
            !sorted.coach || item.coachs.includes(sorted.coach);
          const niveauxMatch =
            !sorted.niveau || item.niveaux.includes(sorted.niveau);

          return schoolMatch && classMatch && coachMatch && niveauxMatch;
        }).length / limit
    )
    : 0;
  const currentDataPage = data
    ? data
      .filter(
        (item) =>
          item?.firstName.toLowerCase().includes(query) ||
          item?.lastName.toLowerCase().includes(query)
      )
      .filter((item) => {
        const schoolMatch = !sorted.ecole || item.schoolId === sorted.ecole;
        const classMatch = !sorted.classe || item.classeId === sorted.classe;

        const coachMatch =
          !sorted.coach || item.coachs.includes(sorted.coach);
        const niveauxMatch =
          !sorted.niveau || item.niveaux.includes(sorted.niveau);

        const no_coach =
          queryParams.get("coach") === "false"
            ? item.coachs.length === 0
            : queryParams.get("coach") === "true"
              ? item.coachs.length > 0
              : item;

        const no_parents =
          queryParams.get("parent") === "false"
            ? item.parrentId === null
            : queryParams.get("parent") === "true"
              ? item.parrentId !== null
              : item;

        const no_bourse =
          queryParams.get("bourse") === "false"
            ? item.parrainId === null
            : queryParams.get("bourse") === "true"
              ? item.parrainId !== null
              : item;

        const eliglbe_bourse =
          queryParams.get("eligible") === "true"
            ? (item.boursier === "ELIGIBLE") & (item.parrainId === null)
            : item;

        return (
          schoolMatch &&
          classMatch &&
          coachMatch &&
          niveauxMatch &&
          no_coach &&
          no_parents &&
          no_bourse &&
          eliglbe_bourse
        );
      })
      .slice(startIndex, endIndex)
    : [];

  const exportToCSV = () => {
    if (!elevesData || !elevesData.data || elevesData.data.length === 0) {
      toast.error("Aucune donnée à exporter");
      return;
    }

    // Accédez aux données dans elevesData
    const formattedData = elevesData.data.map(eleve => ({
      firstName: eleve.firstName,
      lastName: eleve.lastName,
      schoolId: eleve.school?.name || 'Inconnu',
      classeId: eleve.classe?.name || 'Inconnu',
      // Ajoutez d'autres champs ici si nécessaire
    }));

    try {
      const BOM = "\uFEFF";
      const csvData = Papa.unparse(formattedData);
      const blob = new Blob([BOM + csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "eleves.csv");
      link.click();
    } catch (error) {
      console.error("Erreur lors de l'exportation en CSV", error);
      toast.error("Erreur lors de l'exportation en CSV");
    }
  };

  // console.log('elevesData.data', elevesData.data);
  return (
    <Box>
      <Box mb={20}>
        <Paper p={20} className={styles.root}>
          <div className={styles.stat}>
            <Title className={styles.title} mb={10}>
              Eleves
            </Title>
            <Title className={styles.count} order={3}>
              {allDataLoading
                ? "loading"
                : data?.filter((item) => {
                  const schoolMatch =
                    !sorted.ecole || item.schoolId === sorted.ecole;
                  const classMatch =
                    !sorted.classe || item.classeId === sorted.classe;

                  const coachMatch =
                    !sorted.coach || item.coachs.includes(sorted.coach);
                  const niveauxMatch =
                    !sorted.niveau || item.niveaux.includes(sorted.niveau);
                  return (
                    schoolMatch && classMatch && coachMatch && niveauxMatch
                  );
                }).length}
            </Title>
            <Text className={styles.description} maw={500}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            </Text>
          </div>
        </Paper>
      </Box>

      <Flex position={"apart"}>
        <Flex>
          <Text size="xl" fw="bold">
            {queryParams.get("coach") === "false"
              ? "La liste des éleves sans coachs"
              : queryParams.get("coach") === "true"
                ? "La liste des éleves coachés"
                : "La liste de tous les éleves"}
          </Text>
        </Flex>
        <Flex>
          <TextInput
            mx={10}
            placeholder={"Rechercher un eleve"}
            onChange={(value) => setQuery(value.target.value.toLowerCase())}
            value={query}
          />
          <Button
            onClick={() => {
              setItemEdited(null);
              open(true);
            }}
          >
            Ajouter
          </Button>
          <Group spacing="md">
            <div className="button-container mx-2">
              <Button onClick={exportToCSV}
                disabled={isLoading}
                className="button-with-loader"
              >
                {isLoading && <Loader type="bar" className="loader" />}
                Exporter en CSV</Button>
            </div>
          </Group>
        </Flex>
      </Flex>
      <Divider my={10} />

      <Grid mb={10}>
        <Grid.Col lg={3} sm={6} xs={12}>
          <Select
            searchable
            clearable
            data={
              checkTable(ecoles?.data)
                ? ecoles?.data.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                }))
                : []
            }
            placeholder="Ecoles"
            onChange={(e) => setSorted({ ...sorted, ecole: e })}
          />
        </Grid.Col>
        <Grid.Col lg={3} sm={6} xs={12}>
          <Select
            searchable
            clearable
            data={
              checkTable(classes?.data)
                ? classes?.data?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                }))
                : []
            }
            placeholder="Classes"
            onChange={(e) => setSorted({ ...sorted, classe: e })}
          />
        </Grid.Col>
        <Grid.Col lg={3} sm={6} xs={12}>
          <Select
            searchable
            clearable
            onChange={(e) => setSorted({ ...sorted, niveau: e })}
            data={
              checkTable(niveaux?.data)
                ? niveaux?.data?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                }))
                : []
            }
            placeholder="Niveaux"
          />
        </Grid.Col>
        <Grid.Col lg={3} sm={6} xs={12}>
          <Select
            searchable
            clearable
            data={
              checkTable(coachs?.data)
                ? checkTable(is_coached.concat(coachs?.data)) &&
                is_coached.concat(coachs?.data)?.map((item) => ({
                  value: item?.id,
                  label: item?.fullName || "",
                }))
                : []
            }
            placeholder="Coachs"
            onChange={(e) => {
              if (typeof e === "string") {
                setQueryParams({ coach: e });
                return setSorted({ ...sorted, classe: "" });
              } else {
                setQueryParams({ coach: "all" });
                return setSorted({ ...sorted, coach: e });
              }
            }}
          />
        </Grid.Col>
      </Grid>
      {/* <Box my={5}>
        <Text>
          {
            queryParams.get("coach") === "false"
            ?"La liste des éleves sans coachs"
            :queryParams.get("coach") === "true" 
            ?"La liste des éleves coachés"
            :"La liste de tous les éleves"
          }
        </Text>
      </Box> */}

      {firstDataLoading ? (
        <Loader type="bar" />
      ) : (
        <>
          <EleveTable
            data={currentDataPage ?? []}
            handleEdit={(id, item) => {
              setItemEdited(id);
              open();
              form.setValues({ ...item });
            }}
            handleDelete={async (id) => {
              await DeleteAction(deleteEleves, id);
            }}
          />
          <Group position="right" mt={10}>
            <Pagination
              value={currentPage}
              onChange={setCurrentPage}
              total={totalPages}
            />
          </Group>
        </>
      )}

      <Space h={30} />

      {/* My modal */}
      <Modal
        opened={opened}
        title={itemEdited ? "Modifier l'eleve" : "Ajout un eleve"}
        onClose={() => {
          close();
          form.reset();
          setItemEdited(null);
        }}
        size={"lg"}
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            if (itemEdited) {
              await EditAction(editEleves, values);
            } else {
              const res = await AddAction(addEleves, values);
              if (res?.data?.id) {
                toast.success("Eleve ajoute avec success")
                close();
                form.reset();
                await AddAction(addClassesHistorique, { ...newClasse, studentId: res?.data?.id, classeId: res?.data?.classeId })
              }
            }

          })}
        >
          <TextInput
            label={"Prenom"}
            placeholder={"mettre votre prenom"}
            {...form.getInputProps("firstName")}
          />

          <TextInput
            mt={15}
            label={"Nom"}
            placeholder={"mettre votre nom"}
            {...form.getInputProps("lastName")}
          />

          <Select
            {...form.getInputProps("parrentId")}
            placeholder="selectionner un parent"
            label={"Parent"}
            data={
              checkTable(parents?.data)
                ? parents?.data?.map((item) => ({
                  value: item?.id,
                  label: item?.fullName,
                }))
                : []
            }
            mt={15}
            searchable
          />

          <Select
            {...form.getInputProps("parrainId")}
            placeholder="selectionner un parrain"
            label={"Parrain"}
            data={
              checkTable(parrains?.data)
                ? parrains?.data?.map((item) => ({
                  value: item?.id,
                  label: item?.firstName + " " + item?.lastName,
                }))
                : []
            }
            mt={15}
            searchable
          />

          <Select
            {...form.getInputProps("schoolId")}
            mt={15}
            label={"Ecole"}
            data={
              checkTable(ecoles?.data)
                ? ecoles?.data?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                }))
                : []
            }
            placeholder="selectionner une ecole"
            searchable
          />
          <Select
            {...form.getInputProps("abandoned")}
            placeholder="Abandonne"
            label={"Abandonne"}
            data={[
              { value: true, label: "OUI" },
              { value: false, label: "NON" },
            ]}
            mt={15}
            searchable
          />

          {
            !itemEdited &&
            <Select
              searchable
              {...form.getInputProps("classeId")}
              mt={15}
              label={"Classe"}
              data={
                checkTable(classes?.data)
                  ? classes?.data?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))
                  : []
              }
              placeholder="selectionner une classe"
            />
          }

          <MultiSelect
            searchable
            {...form.getInputProps("niveaux")}
            mt={15}
            label={"Niveaux"}
            data={
              checkTable(niveaux?.data)
                ? niveaux?.data?.map((item) => ({
                  label: item?.name,
                  value: item?.id,
                }))
                : []
            }
            placeholder="selectionner les niveaux"
          />

          <MultiSelect
            searchable
            placeholder="selectionner les niveaux"
            {...form.getInputProps("coachs")}
            mt={15}
            label={"Coach"}
            data={
              checkTable(coachs?.data)
                ? coachs?.data?.map((item) => ({
                  label: item?.fullName,
                  value: item?.id,
                }))
                : []
            }
          />

          <Divider my={15} />

          <Flex position={"end"} gap={15}>
            <Button color={"gray"} onClick={() => close()}>
              Annuler
            </Button>
            <Button type={"submit"} loading={addLoading || editLoading}>
              {itemEdited ? "Modifier" : "Ajouter"}
            </Button>
          </Flex>
        </form>
      </Modal>
    </Box>
  );
};

export default ElevePage;
