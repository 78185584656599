import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

export const partenaireApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getPartenaires: build.query({
      query: () => API.PARTENAIRES_API.GETALL(),
      providesTags: ["Partenaires"],
    }),
    addPartenaires: build.mutation({
      query({ body, token }) {
        return {
          url: API.PARTENAIRES_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Partenaires"],
    }),
    EditPartenaires: build.mutation({
      query({ body, token }) {
        return {
          url: API.PARTENAIRES_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Partenaires"],
    }),
    deletePartenaires: build.mutation({
      query(id) {
        return {
          url: API.PARTENAIRES_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Partenaires"],
    }),
  }),
});

export const {
  useGetPartenairesQuery,
  useAddPartenairesMutation,
  useEditPartenairesMutation,
  useDeletePartenairesMutation,
} = partenaireApi;
