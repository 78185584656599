import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetEpreuveQuery } from "../../../redux/features/epreuveApi";
import { ActionIcon, Box, Center, Loader } from "@mantine/core";
import { ArrowLeft } from "tabler-icons-react";
import EpreuveOrganisation from "./types/EpreuveOrganisation";
import EperuveNullType from "./types/NullType";
import EpreuveAlphabet from "./types/EpreuveAlphabet";
import EpreuveDictee from "./types/EpreuveDicte";

const EpreuveDetailPage = () => {
    const params = useParams();
    const navigation = useNavigate()
    const {state} = useLocation()
    const {data:epreuve , isLoading} = useGetEpreuveQuery(state?.id);
    return ( 
        <Box>
            <ActionIcon onClick={() => navigation(-1)}>
                <ArrowLeft />
            </ActionIcon>
            {
                isLoading ?
                <Center>
                    <Loader />
                </Center> 
                :
               <Box>
                    {
                        epreuve?.data?.type ==="organisation"?
                        <EpreuveOrganisation epreuve={epreuve?.data} />:
                        epreuve?.data?.type ===null?    
                        <EperuveNullType epreuve={epreuve?.data}/>:
                        epreuve?.data?.type ==="alphabet"?
                        <EpreuveAlphabet epreuve={epreuve?.data} />:
                        epreuve?.data?.type ==="dictee"?
                        <EpreuveDictee epreuve={epreuve?.data} />:
                        epreuve?.data?.type ==="transcription"?
                        <EpreuveDictee epreuve={epreuve?.data} />:
                        epreuve?.data?.type ==="operation"?
                        <h1>Type operation</h1>:
                        <h1>Details pour le type {epreuve?.data?.type} d'exercice en cours {console.log(epreuve?.data)}</h1>
                    }
               </Box>
            }
        </Box>
    );
}
 
export default EpreuveDetailPage;