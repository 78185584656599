import React, { useEffect, useState } from "react";
import Box from "../../components/Box";
import Text from "../../components/Text";
import Divider from "../../components/Divider";
import { useGetVillesQuery } from "../../redux/features/villeApi";
import {
  useAddCoachsMutation,
  useDeleteCoachsMutation,
  useEditCoachsMutation,
  useGetCoachsQuery,
} from "../../redux/features/coachApi";
import "../../../src/styles.css"
import { useGetNiveauxQuery } from "../../redux/features/niveauApi";
import { useGetEcolesQuery } from "../../redux/features/ecoleApi";
import { useGetClassesQuery } from "../../redux/features/classeApi";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  ActionIcon,
  Button,
  Group,
  Input,
  Modal,
  MultiSelect,
  Select,
  TextInput,
} from "@mantine/core";
import EditAction from "./actions/EditAction";
import AddAction from "./actions/AddAction";
import DeleteAction from "./actions/DeleteAction";
import useTableWithPagination from "../../layouts/table";
import { Link, useNavigate } from "react-router-dom";
import TableWithPagination from "../../layouts/table/TableWithPagination";
import { Eye } from "tabler-icons-react";
import Papa from "papaparse";
import toast from "react-hot-toast";

const CoachPage = () => {
  const { data: coachData, isLoading } = useGetCoachsQuery();
  const { data: villes } = useGetVillesQuery();
  const { data: niveaux } = useGetNiveauxQuery();
  const { data: ecoles } = useGetEcolesQuery();
  const { data: classes } = useGetClassesQuery();
  const [addCoachs, { isLoading: addLoading }] = useAddCoachsMutation();
  const [editCoachs, { isLoading: editLoading }] = useEditCoachsMutation();
  const [deleteCoachs] = useDeleteCoachsMutation();

  const navigation = useNavigate();

  const [itemEdited, setItemEdited] = useState(null);

  const [opened, { open, close }] = useDisclosure(false);

  const [search, setSearch] = useState("");

  const form = useForm({
    initialValues: {
      schools: [],
      villeId: "",
      classes: [],
      fullName: "",
      niveaux: [],
      phone: "",
    },

    validate: {},
  });

  const { TableWithPagination: CoachTable } = useTableWithPagination({
    useQuery: useGetCoachsQuery,
    Component: TableWithPagination,
    searchs: ["fullName", "phone"],
    searchValue: search,
    filters: [
      {
        data: villes ? villes?.data?.formatArray() : [],
        label: "Ville",
        key: "villeId",
      },
    ],
    componentOptions: {
      fields: [
        { label: "id", field: "id" },
        { label: "Prenom et nom", field: "fullName" },
        { label: "Derniere connexion", field: "custum_last_connexion" },
        { label: "Eleves", field: "count_students" },
        { label: "Appels", field: "count_appel" },
        {
          label: "Presences",
          field: "pourcentage_appel.pourcentage_presences",
        },
        { label: "Absences", field: "pourcentage_appel.pourcentage_absences" },
        { label: "Retards", field: "pourcentage_appel.pourcentage_retards" },
        { label: "Fiches", field: "count_fiches" },
        { label: "Ville", field: "ville.name" },
      ],
      navigation,
      handleAdd: open,
      handleEdit: (id, item) => {
        setItemEdited(id);
        form.setValues({
          ...item,
          // diagnosticId: item?.diagnosticId?.id,
        });
        open();
      },
      handleDelete: async (id) => await DeleteAction(deleteCoachs, id),
      otherAction: (id, item) => (
        <>
          <ActionIcon onClick={() => navigation(`${id}`, {
            state: {
              fullName: item.fullName,
              custum_last_connexion: item.custum_last_connexion,
              count_students: item.count_students,
              count_appel: item.count_appel
            }
          })
          }
          >
            <Eye size={18} />
          </ActionIcon>
        </>
      ),
    },
  });

  useEffect(() => {
    // console.log("alla");
  }, []);

  // importation csv
  const exportToCSV = () => {
    if (!coachData || !coachData.data || coachData.data.length === 0) {
      toast.error("Aucune donnée à exporter");
      return;
    }

    // Accédez aux données dans coachData
    const formattedData = coachData.data.map(coach => ({
      fullName: coach.fullName,
      phone: coach.phone,
      city: coach.ville?.name || 'Inconnu',
      // Ajoutez d'autres champs ici si nécessaire
    }));

    try {
      const BOM = "\uFEFF";
      const csvData = Papa.unparse(formattedData);
      const blob = new Blob([BOM + csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "coachs.csv");
      link.click();
    } catch (error) {
      console.error("Erreur lors de l'exportation en CSV", error);
      toast.error("Erreur lors de l'exportation en CSV");
    }
  };
  return (
    <Box>
      <Text size={20}>La liste des coachs</Text>

      <Button size="sm" component={Link} to={"suivi"}>
        suivi coaching
      </Button>
      {/* {CoachTable} */}
      <Group mt={10}>
        <Input
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder="Recherche coach"
          style={{ flex: 1 }}
        />
        <Button title="Ajouter" onClick={open}>
          Ajouter
        </Button>
        <div className="button-container me-2">
          <Button onClick={exportToCSV} disabled={isLoading} className="button-with-loader">
            Exporter en CSV
          </Button>
        </div>
      </Group>
      <CoachTable />
      {/* My modal */}
      <Modal
        size={"lg"}
        opened={opened}
        onClose={() => {
          close();
          form.reset();
          setItemEdited(null);
        }}
        title={itemEdited ? "Modifier un coach" : "Ajouter un coach"}
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            // return console.log(values);
            if (itemEdited) {
              await EditAction(editCoachs, values);
            } else {
              await AddAction(addCoachs, values);
            }
            close();
            form.reset();
          })}
        >
          <TextInput
            label={"Prenom"}
            placeholder={"mettre le prenom"}
            {...form.getInputProps("fullName")}
          />

          <TextInput
            type="tel"
            label={"telephone"}
            placeholder={"77 *** ** **"}
            {...form.getInputProps("phone")}
            mt={15}
          />

          <MultiSelect
            mt={15}
            label={"Ecoles"}
            data={
              ecoles?.data.map((item) => ({
                value: item?.id,
                label: item?.name,
              })) ?? []
            }
            {...form.getInputProps("schools")}
          />

          <Select
            mt={15}
            label={"Ville"}
            data={
              villes?.data.map((item) => ({
                value: item?.id,
                label: item?.name,
              })) ?? []
            }
            {...form.getInputProps("villeId")}
          />

          <MultiSelect
            mt={15}
            label={"Classes"}
            data={classes?.data.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
            {...form.getInputProps("classes")}
          />

          <MultiSelect
            mt={15}
            label={"Niveaux"}
            data={niveaux?.data.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
            {...form.getInputProps("niveaux")}
          />

          <Divider my={15} />
          <Group position={"right"}>
            <Button onClick={close}>Annuler</Button>
            <Button type={"submit"} isLoading={addLoading || editLoading}>
              {itemEdited ? "Modifier" : "Ajouter"}
            </Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default CoachPage;
