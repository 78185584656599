function useGetPaginateData({
  useGetQuery,
  offset = 0,
  limit = 10,
  filter = {},
}) {
  const data = useGetQuery({ ...filter });
  //   const data = useGetQuery({ offset, limit, filter });

  return { ...data };
}

export default useGetPaginateData;
