import {
  Box,
  Button,
  Divider,
  Group,
  Loader,
  Modal,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";
import ClasseTable from "../../components/ClasseTable";
import { useDisclosure } from "@mantine/hooks";
import EditAction from "../../actions/EditAction";
import AddAction from "../../actions/AddAction";
import DeleteAction from "../../actions/DeleteAction";
import { useForm } from "@mantine/form";
import {
  useAddMatiereMutation,
  useDeleteMatieresMutation,
  useEditMatiereMutation,
  useGetMatiereQuery,
} from "../../../../redux/features/matiereApi";

const MatierePage = () => {
  const { data, isLoading } = useGetMatiereQuery();
  const [addClasses, { isLoading: addLoading }] = useAddMatiereMutation();
  const [editClasses, { isLoading: editLoading }] = useEditMatiereMutation();
  const [deleteClasses] = useDeleteMatieresMutation();

  const [query, setQuery] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [itemEdited, setItemEdited] = useState(null);

  const form = useForm({
    initialValues: {
      name: "",
    },

    validate: {
      name: (value) => (value === "" ? "Ce champ est obligatoire" : null),
    },
  });

  return (
    <Box>
      {/* <Text size={20}>ClassePage</Text> */}
      <Group position={"apart"}>
        <TextInput
          placeholder={"Rechercher une matiere"}
          onChange={(value) => setQuery(value.target.value.toLowerCase())}
          value={query}
        />
        <Button onClick={open}>Ajouter</Button>
      </Group>
      <Divider my={20} />

      {isLoading ? (
        <Loader />
      ) : (
        <ClasseTable
          data={data?.data.filterData(["name"], query) ?? []}
          handleEdit={(id, item) => {
            setItemEdited(id);
            form.setValues({
              ...item,
            });
            open();
          }}
          handleDelete={async (id) => await DeleteAction(deleteClasses, id)}
        />
      )}

      {/* My modal */}
      <Modal
        opened={opened}
        onClose={() => {
          close();
          form.reset();
          setItemEdited(null);
        }}
        title={itemEdited ? "Modifier matiere" : "Ajouter matiere"}
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            if (itemEdited) {
              await EditAction(editClasses, values);
            } else {
              await AddAction(addClasses, values);
            }
            close();
            form.reset();
          })}
        >
          <TextInput
            label={"Nom du classe"}
            placeholder={"matiere name"}
            {...form.getInputProps("name")}
          />

          <Divider my={15} />
          <Group position="right">
            <Button color={"gray"} onClick={close}>
              Annuler
            </Button>
            <Button type={"submit"} loading={addLoading || editLoading}>
              {itemEdited ? "Modifier" : "Ajouter"}
            </Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default MatierePage;
