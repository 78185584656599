import React, { useState } from "react";
import {
  useAddDirecteursMutation,
  useDeleteDirecteursMutation,
  useEditDirecteursMutation,
  useGetDirecteursQuery,
} from "../../../redux/features/directeurApi";
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Loader,
  Modal,
  //   Select,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import EditAction from "../actions/EditAction";
import AddAction from "../actions/AddAction";
import DirecteurTable from "../components/DirecteurTable";
import DeleteAction from "../actions/DeleteAction";

function EcoleDirecteurPage() {
  const { data, isLoading } = useGetDirecteursQuery();

  const [add, { isLoading: addLoading }] = useAddDirecteursMutation();
  const [edit, { isLoading: editLoading }] = useEditDirecteursMutation();
  const [remove] = useDeleteDirecteursMutation();

  //   console.log(data);

  const [opened, { open, close }] = useDisclosure(false);
  const [itemEdited, setItemEdited] = useState(null);

  const [query, setQuery] = useState("");

  const form = useForm({
    initialValues: {
      fullName: "",
      phone: "",
      //   schoolId: "",
    },

    validate: {
      fullName: (value) => (value === "" ? "Ce champ est obligatoire" : null),
      phone: (value) => (value === "" ? "Ce champ est obligatoire" : null),
      //   schoolId: (value) => (value === "" ? "Ce champ est obligatoire" : null),
    },
  });

  return (
    <Box>
      <Group position="apart">
        <TextInput
          placeholder="Recherche"
          onChange={(e) => setQuery(e.target.value?.toLowerCase())}
        />
        <Button onClick={open}>Ajouter un directeur</Button>
      </Group>

      <Box my={30}>
        {/* Table */}
        {isLoading ? (
          <Loader />
        ) : data?.data.length === 0 ? (
          <Text>Pas de donnee</Text>
        ) : (
          <DirecteurTable
            data={
              data?.data
                ? data?.data?.filterData(["fullName", "phone"], query)
                : []
            }
            handleEdit={(id, item) => {
              setItemEdited(id);
              form.setValues({ ...item });
              open();
            }}
            handleDelete={async (id) => await DeleteAction(remove, id)}
          />
        )}
      </Box>

      {/* My modal */}
      <Modal
        opened={opened}
        onClose={() => {
          close();
          form.reset();
        }}
        title={itemEdited ? "Modifier le directeur" : "Ajouter un directeur"}
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            if (itemEdited) {
              await EditAction(edit, values);
            } else {
              await AddAction(add, values);
            }
            close();
            form.reset();
          })}
        >
          <TextInput
            label={"Prenom et nom"}
            placeholder={"paull ba"}
            {...form.getInputProps("fullName")}
            mb={10}
          />

          <TextInput
            label={"Telephone"}
            placeholder={"78 3356 65 75"}
            {...form.getInputProps("phone")}
          />
          {/* 
          <Select
            mt={15}
            label={"Selectionner une matiere"}
            data={
              matieres?.data.map((matiere) => ({
                value: matiere?.id,
                label: matiere?.name,
              })) ?? []
            }
            placeholder={"Selectionner"}
            {...form.getInputProps("matiereId")}
          /> */}

          <Divider my={15} />
          <Flex position={"end"} gap={15}>
            <Button color={"gray"} onClick={close}>
              Annuler
            </Button>
            <Button type={"submit"} loading={addLoading || editLoading}>
              {itemEdited ? "Modifier" : "Ajouter"}
            </Button>
          </Flex>
        </form>
      </Modal>
    </Box>
  );
}

export default EcoleDirecteurPage;
