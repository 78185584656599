import {
  CalendarEvent,
  Elevator,
  File,
  FileText,
  Home,
  ReportMoney,
  School,
  Users,
} from "tabler-icons-react";
import {
  ACCEUIL,
  APPELS_PATH,
  COACHS_PATH,
  DIAGNOSTIQUES_PATH,
  DIAGNOSTIQUES_PARENT_PATH,
  ECOLES_PATH,
  ELEVES_PATH,
  FICHES_PATH,
  PAIEMENTS_PATH,
  PARENTS_PATH,
  VILLES_PATH,
  COMPOSITION_PATH,
  PROSPECTS_PATH,
} from "../utils/navigationPaths";

export const sidebarLinks = [
  {
    section: 1,
    links: [
      {
        icon: Home,
        label: "Accueil",
        to: ACCEUIL,
      },
    ],
  },

  {
    section: 2,
    links: [
      {
        icon: Users,
        label: "Eleves",
        to: ELEVES_PATH,
      },
      {
        icon: Users,
        label: "Coachs",
        to: COACHS_PATH,
      },
      {
        icon: Users,
        label: "Parents",
        to: PARENTS_PATH,
      },
      {
        icon: Users,
        label: "Partenaires",
        to: "partenaires",
      },
      {
        icon: Users,
        label: "Prospects",
        to: PROSPECTS_PATH,
      },
    ],
  },

  {
    section: 3,
    links: [
      {
        icon: School,
        label: "Ecoles",
        to: ECOLES_PATH,
      },
      // {
      //   icon: Home,
      //   label: "Classes",
      //   to: CLASSES_PATH,
      // },
      {
        icon: File,
        label: "Fiches",
        to: FICHES_PATH,
      },
      // {
      //   icon: Book2,
      //   label: "Matieres",
      //   to: MATIERES_PATH,
      // },
      // {
      //   icon: Elevator,
      //   label: "Niveaux",
      //   to: NIVEAUX_PATH,
      // },
    ],
  },
  {
    section: 4,
    links: [
      {
        icon: Elevator,
        label: "Villes",
        to: VILLES_PATH,
      },
      {
        icon: CalendarEvent,
        label: "Appels",
        to: APPELS_PATH,
      },
      {
        icon: ReportMoney,
        label: "Paiements",
        to: PAIEMENTS_PATH,
      },
    ],
  },
  {
    section: 4,
    links: [
      {
        icon: Elevator,
        label: "Diagnostiques",
        to: DIAGNOSTIQUES_PATH,
        subLinks: [
          {
            icon: Elevator,
            to: DIAGNOSTIQUES_PARENT_PATH,
            label: "Diagnostiques Parents",
          },
        ],
      },
      // {
      //   icon: Elevator,
      //   to: DIAGNOSTIQUES_PARENT_PATH,
      //   label: "Parents",
      // },
      {
        icon: FileText,
        to: COMPOSITION_PATH,
        label: "Compositions",
      },
    ],
  },
];

export const partenaireSidebarLinks = [
  {
    section: 1,
    links: [
      {
        icon: Home,
        label: "Accueil",
        to: "acceuil",
      },
      {
        icon: Users,
        label: "Parents",
        to: "parents",
      },
    ],
  },
];
