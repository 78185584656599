import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Loader,
  Paper,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";
import { useGetClassesQuery } from "../../../redux/features/classeApi";
import { useGetCoachsQuery } from "../../../redux/features/coachApi";
import { useGetEcolesQuery } from "../../../redux/features/ecoleApi";
import { useGetAllElevesByEcoleByCoachQuery } from "../../../redux/features/eleveApi";
import { useForm } from "@mantine/form";
import { useAddAppelsMutation } from "../../../redux/features/appelApi";
import AddAction from "../actions/AddAction";

function AppelCreatePage() {
  const [query, setQuery] = useState("");

  const { data: classes } = useGetClassesQuery();
  const { data: ecoles } = useGetEcolesQuery();
  const { data: coachs } = useGetCoachsQuery();
  const [add, { isLoading: addLoading }] = useAddAppelsMutation();

  const form = useForm({
    initialValues: {
      classeId: "",
      coachId: "",
      schoolId: "",
      date_appel: null,
      appels: [],
    },

    validate: {},
  });

  // Get all eleves
  const { data, isLoading } = useGetAllElevesByEcoleByCoachQuery({
    coachId: form.values.coachId,
    schoolId: form.values.schoolId,
    classeId: form.values.classeId,
  });

  const handleSendAppel = async () => {
    // console.log(form.values);
    await AddAction(add, form.values);
    form.setFieldValue("appels", []);
  };

  const handleAppel = (eleve, status) => {
    const appel = { studentId: eleve?.id, status };

    const currentAppel = form.values.appels.find(
      (item) => item.studentId === appel?.studentId
    );

    let allAppel = form.values.appels;

    if (currentAppel) {
      allAppel = allAppel.map((item) =>
        item.studentId === appel?.studentId ? appel : item
      );
    } else {
      allAppel.push(appel);
    }

    // console.log({ allAppel, appel });

    form.setFieldValue("appels", [...allAppel]);
  };

  // console.log(form.values.appels);

  return (
    <Box pb={30}>
      AjoutAppelPage
      <Divider my={20} />
      <Grid>
        <Grid.Col lg={4} md={4}>
          <Select
            clearable
            searchable
            placeholder="Selectionner une ecole"
            label={"Ecoles"}
            data={
              ecoles?.data.map((item) => ({
                value: item?.id,
                label: item?.name,
              })) ?? []
            }
            // value={query?.ecole ?? ""}
            // onChange={(e) => setQuery({ ...query, ecole: e })}
            {...form.getInputProps("schoolId")}
          />
        </Grid.Col>
        <Grid.Col lg={4} md={4}>
          <Select
            clearable
            searchable
            placeholder="Selectionner une classe"
            label={"Classes"}
            data={
              classes?.data.map((item) => ({
                value: item?.id,
                label: item?.name,
              })) ?? []
            }
            // value={query?.classe ?? ""}
            // onChange={(e) => setQuery({ ...query, classe: e })}
            {...form.getInputProps("classeId")}
          />
        </Grid.Col>
        <Grid.Col lg={4} md={4}>
          <Select
            clearable
            searchable
            placeholder="Selectionner un coach"
            label={"Coachs"}
            data={
              coachs?.data.map((item) => ({
                value: item?.id,
                label: item?.fullName,
              })) ?? []
            }
            // value={query?.coach ?? ""}
            // onChange={(e) => setQuery({ ...query, coach: e })}
            {...form.getInputProps("coachId")}
          />
        </Grid.Col>
      </Grid>
      <Divider my={20} />
      {!form.values.classeId ||
      !form.values.schoolId ||
      !form.values.coachId ? (
        <Group position="center">
          <Text ta={"center"}>
            Veuillez selectionner l'ensemble des elements si decus <br /> Pour
            ensuite procede a l'appel
          </Text>
        </Group>
      ) : isLoading ? (
        <Loader />
      ) : (
        <>
          <Group mb={20} position="apart">
            <TextInput
              placeholder="recherche"
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              w={400}
            />
            <TextInput
              type="date"
              clearable
              searchable
              placeholder="Selectionner la date"
              {...form.getInputProps("date_appel")}
              w={300}
            />
          </Group>
          <Grid mb={20}>
            {data?.data
              .filter(
                (item) =>
                  item?.firstName.toLowerCase().includes(query.toLowerCase()) ||
                  item?.lastName.toLowerCase().includes(query.toLowerCase())
              )
              .map((item) => (
                <Grid.Col key={item?.id} lg={3} md={4} sm={6}>
                  <Paper p={10} radius={10} withBorder>
                    <Group>
                      <Avatar radius={"lg"}>SI</Avatar>
                      <Box>
                        <Text weight={600}>
                          {item?.firstName} {item?.lastName}
                        </Text>
                        <Text>{item?.classeId?.name}</Text>
                      </Box>
                    </Group>
                    <Divider my={10} />

                    <Group>
                      <Button
                        size="xs"
                        radius={10}
                        color="green"
                        onClick={() => handleAppel(item, "PRESENT")}
                        disabled={form?.values.appels.some(
                          (appel) =>
                            appel.studentId === item?.id &&
                            appel?.status === "PRESENT"
                        )}
                      >
                        Present
                      </Button>
                      <Button
                        size="xs"
                        radius={10}
                        color="orange"
                        onClick={() => handleAppel(item, "RETARD")}
                        disabled={form?.values.appels.some(
                          (appel) =>
                            appel.studentId === item?.id &&
                            appel?.status === "RETARD"
                        )}
                      >
                        Retard
                      </Button>
                      <Button
                        size="xs"
                        radius={10}
                        color="red"
                        onClick={() => handleAppel(item, "ABSENT")}
                        disabled={form?.values.appels.some(
                          (appel) =>
                            appel.studentId === item?.id &&
                            appel?.status === "ABSENT"
                        )}
                      >
                        Absent
                      </Button>
                    </Group>
                  </Paper>
                </Grid.Col>
              ))}
          </Grid>
          <Button onClick={handleSendAppel} loading={addLoading}>
            Evoyer l'appel
          </Button>
        </>
      )}
    </Box>
  );
}

export default AppelCreatePage;
