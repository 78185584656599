import { Box, Divider, Text,Button, Group, Loader, Select } from "@mantine/core";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import HeaderPage from "../../../components/HeaderPage";
import { useGetDiagnostiqueNiveauDetailsQuery } from "../../../redux/features/diagnostiqueApi";
import EpreuveTable from "../components/EpreuveTable";
import { handleArchiveEpreuve } from "./actions";
import { useArchiveEpreuveMutation } from "../../../redux/features/epreuveApi";

const EpreuveByNiveauPage = () => {

    const navigation = useNavigate();
    // const {diagnosticId ,niveauId} =useParams();
    const {state:{niveauName,diagnosticId,niveauId}} = useLocation()
    const body = {diagnosticId,niveauId};
    const {data, isLoading}= useGetDiagnostiqueNiveauDetailsQuery(body)
    const [archiveEpreuve] = useArchiveEpreuveMutation();

    const [queryParams, setQueryParams] = useSearchParams({type:"all"})

    const epreuves_type = [
        {
            id:"all",
            name:"Toutes les exercices"
        },
        {
          id: "parent",
          name:"Type parent"
        },
        {
          id: "coachs",
          name:"Type coach"
        }
      ] 

    return ( 
        <>
            {
                isLoading?(
                    <Loader />
                ):
                (
                   <>
                   {
                    ! data?.data?.lenght ? (
                        <>
                            <Group position="apart">
                                <HeaderPage
                                    navigation={navigation}
                                    title={`Diagnostic : ${niveauName}`}  
                                />
                                <Select
                                    clearable
                                    data={
                                    epreuves_type?.map((item) => ({
                                        value: item?.id,
                                        label: item?.name || "",
                                    })) ??
                                    [] ??
                                    []
                                    }
                                    placeholder="Type d'exercice"
                                    onChange={(e)=> setQueryParams({type:e})}
                                />
                                {/* <Button
                                    // disabled={isLoading || data?.data?.niveaux.length >= 3}
                                    // onClick={open}
                                >
                                Ajouter un exercice
                                </Button> */}
                            </Group>
                            <Divider my={10} />
                            <EpreuveTable 
                            data={
                                data?.data?.filter((item) => queryParams.get("type") === "coachs"
                                    ? item.type_diagnostic === "COACHS"
                                    : queryParams.get("coach") === "parents"
                                    ? item.type_diagnostic === "PARENT"
                                    : item
                                )
                            }
                            handleArchive={(id,value)=> handleArchiveEpreuve(id,value,archiveEpreuve)}
                            />
                        </>
                       ):
                       (
                        <>
                        <Group position="apart">
                            <HeaderPage
                                navigation={navigation}
                                title={`Diagnostic ${data?.data[0]?.diagnostic.split(" ")[0]} : ${data?.data[0]?.niveauId?.name}`}
                            />
                            <Button
                                // disabled={isLoading || data?.data?.niveaux.length >= 3}
                                // onClick={open}
                            >
                            Ajouter niveau
                            </Button>
                        </Group>
                        <Divider my={10}/>
                        <Text mt={10}>Pas d`exercice</Text>
                        </>
                       )
                   }
                   </>
                )
            }
        </>
    );
}
 
export default EpreuveByNiveauPage;