import {
  ActionIcon,
  Box,
  Flex,
  Group,
  Paper,
  Text,
  TextInput,
} from "@mantine/core";
import React from "react";
import { shuffleArray } from "../../../../utils/ShuffleArray";
import { Atom2, X } from "tabler-icons-react";

function LettreManquanteForm({ form, index, choices, correction_dp, id }) {
  return (
    <Paper mb={20} p={10} shadow="xs">
      <TextInput
        style={{ flex: 1 }}
        {...form.getInputProps(`epreuves.${index}.titre`)}
        placeholder="Mettre le titre de l'epreuve"
        mb={10}
      />
      <Group noWrap>
        <Box style={{ flex: 1 }}>
          <Flex gap={8} wrap={"wrap"}>
            {correction_dp.split("-")?.map((item, index) => (
              <TextInput
                placeholder="Mettre la bonne phrase"
                // key={index}
                // {...form.getInputProps(`epreuves.${index}.correction_dp`)}
                style={{ cursor: "pointer" }}
                readOnly
                value={item}
                w={40}
                onClick={() =>
                  form?.setFieldValue("epreuves", [
                    ...form?.values.epreuves.map((epreuve) =>
                      epreuve?.id === id
                        ? { ...epreuve, choices: [...epreuve?.choices, item] }
                        : { ...epreuve }
                    ),
                  ])
                }
                disabled={form?.values.epreuves.some((epreuve) =>
                  epreuve?.id === id
                    ? epreuve?.choices?.some((letter) => letter === item)
                    : false
                )}
              />
            ))}
          </Flex>

          {choices?.length > 0 ? (
            <Text mt={15} size={"sm"} mb={5}>
              Les lettres a remplir sont :
            </Text>
          ) : null}
          <Flex gap={8} wrap={"wrap"}>
            {choices?.length > 0
              ? choices?.map((item, index) => (
                  <TextInput
                    placeholder="Mettre la bonne phrase"
                    key={index}
                    style={{ flex: 1 }}
                    readOnly
                    value={item}
                    // mt={5}
                    w={40}
                    onClick={() =>
                      form?.setFieldValue("epreuves", [
                        ...form?.values.epreuves.map((epreuve) =>
                          epreuve?.id === id
                            ? {
                                ...epreuve,
                                choices: [
                                  ...epreuve?.choices.filter(
                                    (lettre) => lettre !== item
                                  ),
                                ],
                              }
                            : { ...epreuve }
                        ),
                      ])
                    }
                  />
                ))
              : null}
          </Flex>
        </Box>

        {/* {correction_dp ? (
          <ActionIcon
            onClick={() => {
              const sentense = shuffleArray(
                form.values.epreuves[index]?.correction_dp?.trim()?.split(" ")
              );
              form?.insertListItem(`epreuves.${index}.choices`, sentense);
              form?.setFieldValue("epreuves", [
                ...form?.values.epreuves.map((item) =>
                  item?.id === id ? { ...item, choices: sentense } : { ...item }
                ),
              ]);
            }}
            variant="outline"
          >
            <Atom2 size="1rem" />
          </ActionIcon>
        ) : null} */}
        <ActionIcon
          color="red"
          onClick={() => form.removeListItem(`epreuves`, index)}
        >
          <X size="1rem" />
        </ActionIcon>
      </Group>
      {/* <Divider my={15} /> */}
    </Paper>
  );
}

export default LettreManquanteForm;
