import {
  ActionIcon,
  Alert,
  Anchor,
  Box,
  Button,
  Divider,
  Group,
  MultiSelect,
  Notification,
  NumberInput,
  Paper,
  Popover,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useState } from "react";
import { AlertCircle, Check, Plus, X } from "tabler-icons-react";
import { useAddDiagnosticParentsMutation } from "../../../redux/features/diagnosticParentApi";
import AddAction from "./actions/AddAction";
import { useGetDiagnostiquesQuery } from "../api/features/diagnostiqueApi";
import { useGetNiveauxQuery } from "../../../redux/features/niveauApi";
import AlphabetForm from "./components/AlphabetForm";
import OrganisationForm from "./components/OrganisationForm";
import AudioRecordingForm from "./components/AudioRecordingForm";
import TextSpeachForm from "./components/TextSpeachForm";
import LettreManquanteForm from "./components/LettreManquanteForm";
import AudioRecordingWithTimerForm from "./components/AudioRecordingWithTimerForm";
import FigureForm from "./components/FigureForm";
import { useNavigate } from "react-router-dom";
import TSQ from "./components/TSQ";
import { nanoid } from "@reduxjs/toolkit";

function ParentDiagnosticPage() {
  // const { data: classes } = useGetClassesQuery();
  const { data: diagnosticData } = useGetDiagnostiquesQuery();
  // const { data: matieres } = useGetMatiereQuery();
  const { data: niveaux } = useGetNiveauxQuery();

  const navigation = useNavigate();

  const [closed, setClosed] = useState(false);

  // console.log({ diagnosticData });

  const [add, { isLoading, isSuccess, isError }] =
    useAddDiagnosticParentsMutation();

  const form = useForm({
    initialValues: {
      niveauId: "",
      diagnosticId: "",
      epreuves: [],
      bareme: "",
    },

    validate: {
      epreuves: {
        // type: (value) => (!value ? "Obligatoire" : null),
        // consigne: {
        //   value: (value) => (!value ? "Obligatoire" : null),
        // },
        // correction: (value) => (!value ? "Obligatoire" : null),
        // choices: (value) => (!value ? "Obligatoire" : null),
      },
    },
  });

  const [errors, setErrors] = useState([]);

  const diagnosticsMath = form.values.epreuves.map(
    ({ consigne, choices, type }, _index) =>
      type === "operation" ? (
        <Box key={_index}>
          <TextInput
            placeholder="metre la consigne"
            mb={10}
            radius={10}
            rows={1}
            {...form.getInputProps(`epreuves.${_index}.titre`)}
          />
          <Group position="apart">
            <Group>
              {consigne.map((item, index) =>
                item?.type === "n" ? (
                  <div
                    className=""
                    key={index}
                    style={{ position: "relative" }}
                  >
                    <TextInput
                      type="number"
                      // placeholder="3"
                      {...form.getInputProps(
                        `epreuves.${_index}.consigne.${index}.value`
                      )}
                      radius={10}
                      w={80}
                      placeholder={"nombre"}
                    />
                    {consigne.length === index + 1 ? (
                      <div
                        className=""
                        style={{
                          position: "absolute",
                          top: -10,
                          right: -5,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          form.removeListItem(
                            `epreuves.${_index}.consigne`,
                            index
                          )
                        }
                      >
                        <X color="red" size={"1.1rem"} />
                      </div>
                    ) : null}
                  </div>
                ) : item?.type === "s" ? (
                  <div
                    className=""
                    key={index}
                    style={{ position: "relative" }}
                  >
                    <Select
                      placeholder="signe"
                      {...form.getInputProps(
                        `epreuves.${_index}.consigne.${index}.value`
                      )}
                      radius={10}
                      data={[
                        { value: "+", label: "+" },
                        { value: "-", label: "-" },
                        { value: "*", label: "x" },
                        { value: "/", label: "/" },
                        { value: "=", label: "=" },
                        { value: ">", label: ">" },
                        { value: "<", label: "<" },
                      ]}
                      w={80}
                    />
                    {consigne.length === index + 1 ? (
                      <div
                        className=""
                        style={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          form.removeListItem(
                            `epreuves.${_index}.consigne`,
                            index
                          )
                        }
                      >
                        <X color="red" />
                      </div>
                    ) : null}
                  </div>
                ) : null
              )}

              <ActionIcon
                color="green"
                onClick={() => {
                  if (consigne.at(-1).type === "n") {
                    form.insertListItem(`epreuves.${_index}.consigne`, {
                      id: form.values.epreuves[_index].consigne.length + 1,
                      value: "",
                      type: "s",
                    });
                  } else {
                    form.insertListItem(`epreuves.${_index}.consigne`, {
                      id: form.values.epreuves[_index].consigne.length + 1,
                      value: "",
                      type: "n",
                    });
                  }
                }}
              >
                <Plus size="1rem" />
              </ActionIcon>
            </Group>
            <Group>
              <MultiSelect
                radius={10}
                {...form.getInputProps(`epreuves.${_index}.correction_dp`)}
                data={[
                  ...consigne
                    .map(({ _, value }) =>
                      value
                        ? {
                            value,
                            label: value,
                          }
                        : null
                    )
                    .filter((item) => item !== null),
                ]}
                width={100}
                placeholder="Inconnues"
              />
              <ActionIcon
                color="red"
                onClick={() => form.removeListItem(`epreuves`, _index)}
              >
                <X size="1rem" />
              </ActionIcon>
            </Group>
          </Group>

          <Box mt={10}>
            <Text>Mettre des options pour les choix</Text>
            <Group>
              {choices.map((item, index) => (
                <TextInput
                  key={index}
                  {...form.getInputProps(`epreuves.${_index}.choices.${index}`)}
                  radius={10}
                  w={80}
                  placeholder={"valeur"}
                />
              ))}
              <ActionIcon
                color="green"
                onClick={() => {
                  form.insertListItem(`epreuves.${_index}.choices`, "");
                }}
              >
                <Plus size="1rem" />
              </ActionIcon>
            </Group>
          </Box>

          <Divider my={20} />
        </Box>
      ) : type === "win" || type === "wil" ? (
        <Box key={_index}>
          <TextInput
            placeholder="metre la consigne"
            mb={10}
            radius={10}
            rows={1}
            {...form.getInputProps(`epreuves.${_index}.titre`)}
          />

          <Group position="apart">
            <Group>
              {consigne?.map((item, index) => (
                <TextInput
                  key={index}
                  type={type === "wil" ? "text" : "number"}
                  // placeholder="3"
                  {...form.getInputProps(
                    `epreuves.${_index}.consigne.${index}`
                  )}
                  radius={10}
                  w={type === "wil" ? 120 : 80}
                  placeholder={
                    type === "wil" ? "mettre le nombre en lettre" : "nombre"
                  }
                />
              ))}

              <ActionIcon
                color="green"
                onClick={() => {
                  form.insertListItem(`epreuves.${_index}.consigne`, "");
                }}
              >
                <Plus size="1rem" />
              </ActionIcon>
            </Group>
            <Group>
              <ActionIcon
                color="red"
                onClick={() => form.removeListItem(`epreuves`, _index)}
              >
                <X size="1rem" />
              </ActionIcon>
            </Group>
          </Group>
          <Divider my={20} />
        </Box>
      ) : type === "figure" ? (
        <FigureForm
          key={_index}
          form={form}
          index={_index}
          consigne={consigne}
        />
      ) : (
        <Box key={_index}>
          <TextInput
            placeholder="metre la consigne"
            mb={10}
            radius={10}
            rows={1}
            {...form.getInputProps(`epreuves.${_index}.titre`)}
          />

          <Group position="apart">
            <Group>
              {consigne?.map((item, index) => (
                <TextInput
                  key={index}
                  type={type === "win" ? "text" : "number"}
                  // placeholder="3"
                  {...form.getInputProps(
                    `epreuves.${_index}.consigne.${index}`
                  )}
                  radius={10}
                  w={80}
                  placeholder={"nombre"}
                />
              ))}

              <ActionIcon
                color="green"
                onClick={() => {
                  form.insertListItem(`epreuves.${_index}.consigne`, "");
                }}
              >
                <Plus size="1rem" />
              </ActionIcon>
            </Group>
            <Group>
              <ActionIcon
                color="red"
                onClick={() => form.removeListItem(`epreuves`, _index)}
              >
                <X size="1rem" />
              </ActionIcon>
            </Group>
          </Group>
          <Divider my={20} />
        </Box>
      )
  );

  // useEffect(() => {
  //   console.log({ epreuve: form.values.epreuves });
  // }, [form.values.epreuves]);

  /**
   *
   */
  const diagnosticsLecture = form.values.epreuves.map(
    ({ consigne, choices, type, correction_dp, id }, _index) =>
      type === "alphabet" ? (
        <AlphabetForm
          key={_index}
          index={_index}
          form={form}
          correction_dp={correction_dp}
        />
      ) : type === "tsq" ? (
        <TSQ
          key={_index}
          index={_index}
          form={form}
          correction_dp={correction_dp}
        />
      ) : type === "transcription" ? (
        <AudioRecordingForm form={form} key={_index} index={_index} />
      ) : type === "dictee" ? (
        <TextSpeachForm key={_index} form={form} index={_index} />
      ) : type === "lettreManquante" ? (
        <LettreManquanteForm
          key={_index}
          form={form}
          index={_index}
          id={id}
          choices={choices}
          correction_dp={correction_dp}
        />
      ) : type === "dicteeChronometree" ? (
        <AudioRecordingWithTimerForm key={_index} form={form} index={_index} />
      ) : type === "figure" ? (
        <FigureForm
          key={_index}
          form={form}
          index={_index}
          consigne={consigne}
        />
      ) : (
        <OrganisationForm
          key={_index}
          form={form}
          index={_index}
          id={id}
          choices={choices}
          correction_dp={correction_dp}
        />
      )
  );

  /**
   *
   */
  const handleSubmitMath = form.onSubmit(async (values) => {
    // console.log(values);

    if (!values?.bareme) {
      return alert("Il faudra mettre le bareme");
    }

    let isError = false;
    const reformdata = values.epreuves.map((item) => {
      if (item.type === "operation") {
        let operations = [];
        item.consigne.map((item) => operations.push(item.value));
        return {
          ...item,
          consigne: item.consigne?.map((item) => {
            const valide = eval(operations.join("").split("=")[0]);
            // console.log({ valide });
            if (
              valide ||
              valide === parseInt(operations.join("").split("=")[1])
            ) {
              return { ...item };
            } else {
              setErrors([...errors, operations.join("")]);
              return (isError = true);
            }
          }),
        };
      } else if (item.type === "oc") {
        return {
          ...item,
          correction_dp: item?.consigne.toSorted((a, b) => a - b),
        };
      } else if (item.type === "od") {
        return {
          ...item,
          correction_dp: item?.consigne.toSorted((a, b) => b - a),
        };
      } else if (item.type === "figure") {
        return {
          ...item,
          correction_dp: item?.correction_dp,
        };
      } else if (item.type === "win" || item.type === "wil") {
        return {
          ...item,
          correction_dp: item?.consigne,
        };
      } else {
        return { ...item };
      }
    });

    // return console.log({ reformdata });

    if (isError) {
      // console.log("y a une erreur");
      return;
    }

    try {
      const newData = {
        ...values,
        epreuves: reformdata.map((item) =>
          item.type === "operation"
            ? {
                ...item,
                consigne: item?.consigne.map((item) =>
                  JSON.stringify({ type: item?.type, value: item?.value })
                ),
              }
            : item
        ),
      };

      // return console.log(newData);
      await AddAction(add, newData);
      // console.log({ res });
      navigation(-1);
      setClosed(true);
      form.reset();
    } catch (error) {
      console.log(error);
    }
  });

  /**
   *
   */
  const handleSubmitLecture = form.onSubmit(async (values) => {
    // return console.log({ values });

    if (!values?.bareme) {
      return alert("Il faudra mettre le bareme");
    }
    // return console.log({
    //   ...values,
    //   epreuves: values?.epreuves.map((item) => ({
    //     ...item,
    //     choices:
    //       typeof item?.choices !== "number"
    //         ? [...item?.choices]
    //         : [item?.choices?.toString()],
    //     correction_dp:
    //       item?.type === "alphabet" || item?.type === "lettreManquante"
    //         ? item?.correction_dp.split("-")
    //         : item?.type === "figure"
    //         ? item?.correction_dp
    //         : item?.type === "tsq"
    //         ? [JSON.stringify(item?.correction_dp)]
    //         : item?.type === "dicteeChronometree"
    //         ? [item?.correction_dp]
    //         : item?.correction_dp.split(" "),
    //   })),
    // });

    try {
      // return console.log(newData);
      await AddAction(add, {
        ...values,
        epreuves: values?.epreuves.map((item) => ({
          ...item,
          choices:
            typeof item?.choices !== "number"
              ? [...item?.choices]
              : [item?.choices?.toString()],
          correction_dp:
            item?.type === "alphabet" || item?.type === "lettreManquante"
              ? item?.correction_dp.split("-")
              : item?.type === "figure"
              ? item?.correction_dp
              : item?.type === "tsq"
              ? [JSON.stringify(item?.correction_dp)]
              : item?.type === "dicteeChronometree"
              ? [item?.correction_dp]
              : item?.correction_dp.split(" "),
        })),
      });
      // console.log({ res });
      navigation(-1);
      setClosed(true);
      form.reset();
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <Box pb={30}>
      {closed && isSuccess ? (
        <Notification
          mb={20}
          color="green"
          title="Ajout d'epreuves"
          icon={<Check size="1.2rem" />}
          onClose={() => setClosed(false)}
        >
          Ajout des epreuves reussi
        </Notification>
      ) : closed && isError ? (
        <Notification
          onClose={() => setClosed(false)}
          mb={20}
          color="red"
          title="Ajout d'epreuves"
          icon={<X size="1.2rem" />}
        >
          Une erreur c'est produite lors de l'ajout des epreuves
        </Notification>
      ) : null}

      <form
        onSubmit={(values) =>
          niveaux?.data.find((item) => item?.id === form?.values?.niveauId)
            ?.matiere?.name === "MATHEMATIQUE"
            ? handleSubmitMath(values)
            : handleSubmitLecture(values)
        }
      >
        <Group>
          <Select
            placeholder="Selectionner"
            label="Diagnostique"
            data={
              diagnosticData?.data.map((item) => ({
                value: item?.id,
                label: item?.title + ` (${item?.type_diagnostique})`,
              })) ?? []
            }
            withAsterisk
            radius={10}
            mb={20}
            {...form.getInputProps("diagnosticId")}
            w={300}
            // onChange={() => form.setFieldValue("niveauId", "")}
          />

          <Select
            placeholder="Selectionner"
            label="Niveaux"
            data={
              niveaux?.data
                .map((item) => ({
                  value: item?.id,
                  label: item?.name,
                }))
                ?.filter((item) => {
                  // console.log(item?.label.split(" ").at(-1));
                  return item?.label
                    .split(" ")
                    ?.at(-1)
                    ?.toLowerCase()
                    .includes(
                      diagnosticData?.data
                        ?.find(
                          (diagnostic) =>
                            diagnostic?.id === form?.values?.diagnosticId
                        )
                        ?.title.split(" ")
                        ?.at(-1)
                        .toLowerCase()
                    );
                }) ?? []
            }
            withAsterisk
            radius={10}
            mb={20}
            disabled={!form?.values.diagnosticId}
            {...form.getInputProps("niveauId")}
          />

          <NumberInput
            withAsterisk
            radius={10}
            mb={20}
            placeholder="0"
            label={"Bareme"}
            {...form.getInputProps("bareme")}
          />
        </Group>

        {errors.length > 0 ? (
          <Alert
            icon={<AlertCircle size="1.1rem" />}
            title="Operation Invalide"
            color="red"
            radius="lg"
            withCloseButton
            mt={20}
            onClose={() => setErrors([])}
          >
            {errors.map((error) => (
              <Text>{error}</Text>
            ))}
          </Alert>
        ) : null}

        {form.values.diagnosticId && form.values.niveauId ? (
          <>
            {niveaux?.data.find((item) => item?.id === form?.values?.niveauId)
              ?.matiere?.name === "MATHEMATIQUE" ? (
              <>
                <Paper p={10} withBorder radius={10} my={20}>
                  <Box>
                    {diagnosticsMath}

                    <Popover position="bottom" withArrow shadow="md">
                      <Popover.Target>
                        <Button radius={10}>Ajouter une epreuve</Button>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: [],
                              type: "operation",
                              titre: "Trouver l'inconnue",
                              choices: [""],
                              consigne: [
                                { id: 1, type: "n", value: "" },
                                { id: 2, type: "s", value: "" },
                                { id: 3, type: "n", value: "" },
                                { id: 4, type: "s", value: "=" },
                                { id: 5, type: "n", value: "" },
                              ],
                            })
                          }
                        >
                          Operation
                        </Anchor>
                        <Divider my={5} />
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: "",
                              type: "figure",
                              titre: "Choisie le cercle",
                              choices: [],
                              consigne: [""],
                            })
                          }
                        >
                          Figure
                        </Anchor>
                        <Divider my={5} />
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: [],
                              type: "oc",
                              titre:
                                "Mettre dans l'ordre croissant les nombres",
                              consigne: [""],
                              choices: [""],
                            })
                          }
                        >
                          Ordre croissant
                        </Anchor>
                        <Divider my={5} />
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: [],
                              type: "od",
                              titre:
                                "Mettre dans l'ordre decroissant les nombres",
                              consigne: [""],
                              choices: [""],
                            })
                          }
                        >
                          Ordre decroissant
                        </Anchor>
                        {/* <Divider my={5} />
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: [],
                              type: "win",
                              titre:
                                "Ecris les nombres que vous entendez en chiffre",
                              consigne: [""],
                              choices: [""],
                            })
                          }
                        >
                          Ecrire en chiffre
                        </Anchor>
                        <Divider my={5} />
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: [],
                              type: "wil",
                              titre:
                                "Ecris les nombres que vous entendez en lettre",
                              consigne: [""],
                              choices: [""],
                            })
                          }
                        >
                          Ecrire en lettre
                        </Anchor> */}
                      </Popover.Dropdown>
                    </Popover>
                  </Box>
                </Paper>
              </>
            ) : (
              <Box radius={10} my={20}>
                {diagnosticsLecture}
                <Popover position="bottom" withArrow shadow="md">
                  <Popover.Target>
                    <Button radius={10}>Ajouter une epreuve</Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp: "",
                          type: "organisation",
                          titre: "Reorganiser la phrase",
                          choices: [],
                          consigne: [""],
                        })
                      }
                    >
                      Organisation des mots
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp: [
                            {
                              titre: "",
                              id: nanoid(),
                              responses: [
                                { titre: "", isCorrect: false, id: nanoid() },
                              ],
                            },
                          ],
                          type: "tsq",
                          titre: "Lis le texte puis réponds aux questions.",
                          choices: [],
                          consigne: [""],
                        })
                      }
                    >
                      Text suivis de Questions
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp: "",
                          type: "figure",
                          titre: "Choisie le cercle",
                          choices: [],
                          consigne: [""],
                        })
                      }
                    >
                      Figure
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp:
                            "A-B-C-D-E-F-G-H-I-J-K-L-M-N-O-P-Q-R-S-T-U-V-W-X-Y-Z",
                          type: "alphabet",
                          titre: "Lister les lettres de l'alphabet.",
                          choices: [],
                          consigne: [],
                        })
                      }
                    >
                      Alphabet
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp:
                            "A-B-C-D-E-F-G-H-I-J-K-L-M-N-O-P-Q-R-S-T-U-V-W-X-Y-Z",
                          type: "lettreManquante",
                          titre:
                            "Remplir les lettres manquantes de l'alphabet.",
                          choices: [],
                          consigne: [],
                        })
                      }
                    >
                      Lettre manquante
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp: "",
                          type: "dictee",
                          titre: "Écrivez les sons que vous entendez.",
                          choices: [],
                          consigne: [],
                        })
                      }
                    >
                      dictee
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp: "",
                          type: "dicteeChronometree",
                          titre: "Liser le text que vous voyez en 1 minute.",
                          choices: 1,
                          consigne: [],
                        })
                      }
                    >
                      dictee chronometree
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp: "",
                          type: "transcription",
                          titre: "Liser le mot que vous voyez.",
                          choices: [],
                          consigne: [],
                        })
                      }
                    >
                      transcription
                    </Anchor>
                  </Popover.Dropdown>
                </Popover>
              </Box>
            )}
            <Group position="right">
              <Button radius={10} color="gray" onClick={() => form.reset()}>
                Annuler
              </Button>
              <Button radius={10} type="submit" loading={isLoading}>
                Enregistrer les epreuves
              </Button>
            </Group>
          </>
        ) : (
          <Alert icon={<AlertCircle size="1rem" />} color="orange" radius="lg">
            Veillez selection la matiere et la classe
          </Alert>
        )}
      </form>
    </Box>
  );
}

export default ParentDiagnosticPage;
