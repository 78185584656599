import { useMemo, useState } from "react";
import { Table, ScrollArea, Group, Text, Badge, Box, ActionIcon } from "@mantine/core";
import { Calendar, Trash } from "tabler-icons-react";
import { sortData } from "../../../utils/tableActions";
import Th from "../../../components/Th";
import { useDeleteAppelsMutation } from "../../../redux/features/appelApi";
import toast from "react-hot-toast";

export default function AppelTable({ data, handleEdit }) {
  // const [search, setSearch] = useState("");
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const [handleDeleteAppel]= useDeleteAppelsMutation()

  // const navigation = useNavigate();

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search: "" }));
  };

  const handleDelete= async (id)=>{
    try {
      await handleDeleteAppel(id);
      toast.success("Appel supprime")
    } catch (error) {
      toast.error("Une erreu est survenu")
    }
  }

  useMemo(() => {
    setSortedData(data);
    // console.log(data);
  }, [data]);

  const rows = sortedData?.map((row) => (
    <tr key={row?.id}>
      <td>
        <Group>
          <Calendar color="gray" size={20} />
          <Text weight={600}>
            {new Date(row?.date_appel).toLocaleDateString("fr", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
          </Text>
        </Group>
      </td>
      <td>
        <Group noWrap>
          {/* <Avatar radius={"xl"} color="orange">
            {row?.student?.firstName.at(0)}
            {row?.student?.lastName.at(0)}
          </Avatar> */}
          <Box>
            <Text weight={600} style={{ whiteSpace: "nowrap" }}>
              {row?.student?.full_name}
            </Text>
            <Text>{row?.student?.classe?.name}</Text>
          </Box>
        </Group>
      </td>
      <td>
        {row?.coach ? (
          <Group noWrap>
            {/* <Avatar radius={"xl"} color="yellow">
              {row?.student?.firstName.at(0)}
              {row?.student?.lastName.at(0)}
            </Avatar> */}
            <Text weight={600} style={{ whiteSpace: "nowrap" }}>
              {row?.coach?.fullName}
            </Text>
          </Group>
        ) : (
          <Text>Pas de coach</Text>
        )}
      </td>
      <td>
        <Badge
          color={
            row?.status === "ABSENT"
              ? "red"
              : row?.status === "PRESENT"
              ? "green"
              : "orange"
          }
        >
          {row?.status}
        </Badge>
      </td>
      <td>
        <ActionIcon onClick={() => handleDelete(row.id)}>
          <Trash size={15} color="red" />
        </ActionIcon>
      </td>
      {/* <td>
        <Group position="right">
          <ActionIcon
            color="blue"
            onClick={() => navigation(`${row.id}`, { state: row.title })}
          >
            <Eye size={15} />
          </ActionIcon>
          <ActionIcon color="green" onClick={() => handleEdit(row.id, row)}>
            <Edit size={15} />
          </ActionIcon>
          <ActionIcon onClick={() => handleDelete(row.id)}>
            <Trash size={15} color="red" />
          </ActionIcon>
        </Group>
      </td> */}
    </tr>
  ));

  return (
    <ScrollArea>
      {/* <TextInput
        placeholder="Search by any field"
        mb="md"
        icon={<Search size="1rem" />}
        value={search}
        onChange={handleSearchChange}
      /> */}
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw={700}
        sx={{ tableLayout: "fixed" }}
        bg={"#fff"}
      >
        <thead>
          <tr>
            <Th
              sorted={sortBy === "fullName"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("fullName")}
            >
              date
            </Th>
            <Th
              sorted={sortBy === "fullName"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("fullName")}
            >
              eleves
            </Th>
            <Th
              sorted={sortBy === "phone"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("phone")}
            >
              Fait par
            </Th>
            <Th
              sorted={sortBy === "phone"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("phone")}
            >
              Status d'appel
            </Th>
            <Th>
              Actions
            </Th>
            
            {/* <th style={{ textAlign: "right" }}>action</th> */}
          </tr>
        </thead>
        <tbody>
          {rows?.length > 0 ? (
            rows
          ) : (
            <tr>
              {/* <td colSpan={Object.keys(data[0]).length}> */}
              <Text weight={500} align="center">
                Nothing found
              </Text>
              {/* </td> */}
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}
