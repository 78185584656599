// Melanger les mots
export function shuffleArray(inputArray) {
  // Copiez le tableau d'entrée pour éviter de modifier l'original.
  const array = inputArray.slice();
  for (let i = array.length - 1; i > 0; i--) {
    // Générez un index aléatoire entre 0 et i.
    const j = Math.floor(Math.random() * (i + 1));
    // Échangez les éléments à l'index i et j.
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
