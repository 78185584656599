// eslint-disable-next-line no-extend-native
export default Array.prototype.filterData = function (champs = [], query) {
  return this.filter((item) =>
    champs.some((champ) =>
      item[champ]?.toLowerCase()?.includes(query?.toLowerCase())
    )
  );
};

// const donnees = [
//   { nom: "nom", age: 30, ville: "Paris" },
//   { nom: "abdpu", age: 25, ville: "Londres" },
//   { nom: "abou", age: 35, ville: "New York" },
// ];

// const champsFiltres = ["nom", "ville"];

// const resultatFiltre = donnees.filterData(champsFiltres, "ab");
// console.log(resultatFiltre);
