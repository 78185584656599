import { Box, Divider, Flex, Input, Loader, ScrollArea, Table, Text } from '@mantine/core';
import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';

export default function CoachAppelDetails() {

    const params = useParams();
    const {state} = useLocation()
    console.log({state})
    console.log({params})
    const [query, setQuery] = useState("");
    // const { data , isLoading : isConverting} = useConvertirProspectQuery(prosepectId, {skip : !prosepectId})
    // http://localhost:8000/api/v1/m1/appels/coachs/?date=2023-09-05&coachId=150
    const prospects = []
    const isLoading = false;

    const rows = prospects?.data?.filter(data => data?.fullName?.toLowerCase().includes(query))
        .map((data) => (
            <tr key={data.id}>
                <td>{data.fullName}</td>
                <td>{data.phone}</td>
                <td colSpan={2}>{data.email}</td>
                <td>{data.fullNameEnfant}</td>
                <td>{data.school}</td>
                <td>{data.classe.name}</td>
            </tr>
      ));
    return (
        <div>
            <Box>
                <Text size="xl">
                    La liste des appels
                </Text>
            </Box>
            <Flex>
                <Input
                    placeholder={"Rechercher un parent"}
                    onChange={(e) => setQuery(e.target.value.toLowerCase())}
                    value={query}
                />
            </Flex>
            <Divider my={10}/>
            {
                isLoading?
                <Loader />:
                (
                    <ScrollArea>
                        <Table
                            horizontalSpacing="md"
                            verticalSpacing="xs"
                            miw={1124}
                            sx={{ tableLayout: "fixed" }}
                            bg={"#fff"}
                        >
                            <thead>
                            <tr>
                                <th>Eleve </th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </Table>
                    </ScrollArea>
                )
            }
            
        </div>
    )
}
