import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const directeurApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getDirecteurs: build.query({
      query: () => API.DIRECTEUR.GETALL(),
      providesTags: ["Directeurs"],
    }),
    addDirecteurs: build.mutation({
      query({ body, token }) {
        return {
          url: API.DIRECTEUR.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Directeurs"],
    }),
    EditDirecteurs: build.mutation({
      query({ body, token }) {
        return {
          url: API.DIRECTEUR.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Directeurs"],
    }),
    deleteDirecteurs: build.mutation({
      query(id) {
        return {
          url: API.DIRECTEUR.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Directeurs"],
    }),
  }),
});

export const {
  useGetDirecteursQuery,
  useAddDirecteursMutation,
  useEditDirecteursMutation,
  useDeleteDirecteursMutation,
} = directeurApi;
