import { ActionIcon, Box, Button, Divider, Flex, Input, Loader, ScrollArea, Table, Text } from '@mantine/core';
import React, { useState } from 'react'
import Th from '../../components/Th';
import InputText from '../../components/InputText';
import { Send } from 'tabler-icons-react';
import { parentApi, useConvertirProspectQuery, useGetProspectsQuery } from '../../redux/features/parentApi';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import DetailsParent from './DetailsParent';
import TabViews from '../../components/TabViews';
import { Outlet } from 'react-router-dom';

export default function ProspectPage() {
    // const [query, setQuery] = useState("");
    // const { data:prospects,isLoading, refetch} = useGetProspectsQuery();
    // const { data , isLoading : isConverting} = useConvertirProspectQuery(prosepectId, {skip : !prosepectId})
    const dispatch = useDispatch()

    const tabs = [
        { label: "Liste des contacts", path: "" },
        { label: "Liste des prospects", path: "prospsects" },
        { label: "Liste des clients", path: "clients" },
        { label: "Messages", path: "messages" },
      ];

    // const hanldeConvert = async(id)=>{
    //     const { status, data, error } = dispatch(
    //         parentApi.endpoints.convertirProspect.initiate(id)
    //         // parentApi.endpoints.getPokemon.initiate('bulbasaur')
    //       ).unwrap()
    //     .then(res =>{
    //             toast.success("Prospect converti avec success")
    //             refetch()
    //         }
    //     )
    //     .catch(error=> toast.error(error?.data?.message))
        

    // }


    // const rows = prospects?.data
    //     .filter(data => data?.fullName?.toLowerCase().includes(query))
    //     .map((data) => (
    //         <tr key={data.id}>
    //         <td>{data?.fullName}</td>
    //         <td>{data?.phone}</td>
    //         <td >{data?.countEnfant}</td>
    //         <td >{data?.provenance}</td>
    //         <td >{data?.type==="CONFIRMED" ? "CLIENT":data?.type}</td>
    //         <td>
    //             {/* <Button disabled={data.isConverted ||isConverting} loading={isConverting} onClick={()=>hanldeConvert(data.id)}> */}
    //             <Button disabled={data?.type === "CONFIRMED"} onClick={()=>hanldeConvert(data.id)}>
    //                 {
    //                     data?.type === "CONFIRMED" ? "Deja converti" : "Convertir"
    //                 }
    //             </Button>
    //         </td>
    //         <td>
    //             {/* <Button onClick={open}>Open Modal</Button> */}
    //             <DetailsParent parent={{id:data?.id,fullName:data?.fullName}} />
    //         </td>
    //         </tr>
    //   ));

    return (
        <div>
            <Box>
                <Text size="xl">
                    La liste des prospects
                </Text>
            </Box>
            <TabViews tabs={tabs} />
            <Box>
                <Outlet />
            </Box>
            {/* <Flex>
            <Input
                placeholder={"Rechercher un prospect"}
                onChange={(e) => setQuery(e.target.value.toLowerCase())}
                value={query}
            />
            </Flex>
            <Divider my={10}/>
            {
                isLoading?
                <Loader />:
                (
                    <ScrollArea>
                        <Table
                            horizontalSpacing="md"
                            verticalSpacing="xs"
                            miw={1124}
                            sx={{ tableLayout: "fixed" }}
                            bg={"#fff"}
                        >
                            <thead>
                            <tr>
                                <th>Parent</th>
                                <th>Telephone</th>
                                <th>Nombre enfants</th>
                                <th>Provenance</th>
                                <th>Status</th>
                                <th>Convertir en parent</th>
                                <th>Details</th>
                            </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </Table>
                    </ScrollArea>
                )
            } */}

       
            
        </div>
    )
}
