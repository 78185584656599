import { Badge, Box, Text } from "@mantine/core";
import EperuveTypeHeader from "./compenents/EpreuveTypeHeader";

const EpreuveAlphabet = ({epreuve}) => {
    return ( 
        <Box>
            <EperuveTypeHeader epreuve={epreuve} />
            {
                console.log("epreuve", epreuve)
            }
            <Box mt={15}>
                <Text>La correction</Text>
                {
                    epreuve?.correction_dp?.join(" ").split(" ")
                    .map((choice) =>
                        <Badge 
                            color="gray" 
                            className="text-dark me-3"
                            variant="outline" 
                        >
                            {choice} 
                        </Badge>)
                }
            </Box>
        </Box>
    );
}
 
export default EpreuveAlphabet;