import React from "react";
import { Divider, Text } from "@mantine/core";
import TabViews from "../../components/TabViews";
import { Outlet } from "react-router-dom";

const tabs = [
  { label: "Liste des ecoles", path: "" },
  { label: "Liste des directeurs", path: "directeur" },
  { label: "Liste des maitres", path: "maitre" },
  { label: "Liste des parrains", path: "parrains" },
  { label: "Liste des Classes", path: "class" },
  { label: "Liste des matieres", path: "matieres" },
  { label: "Liste des niveaux", path: "niveaux" },
];

const EcolePage = () => {
  return (
    <div>
      <Text size={20}>Gestion des ecoles</Text>
      <TabViews tabs={tabs} />
      <Divider mb={20} />
      <div className="">
        <Outlet />
      </div>
    </div>
  );
};

export default EcolePage;
