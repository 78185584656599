import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderPage from "../../components/HeaderPage";
import {
  ActionIcon,
  Badge,
  Button,
  Divider,
  Group,
  Loader,
  Modal,
  Paper,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { useGetDiagnostiqueQuery } from "./api/features/diagnostiqueApi";
import { Check, Edit, Eye, Trash } from "tabler-icons-react";
// import DeleteAction from "./actions/DeleteAction";
import {
  useAddDiagnosticsNiveauMutation,
  useDeleteDiagnosticsNiveauMutation,
  useEditDiagnosticsNiveauMutation,
} from "./api/features/mutationsApi";
import EditAction from "./actions/EditAction";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { useGetNiveauxQuery } from "../../redux/features/niveauApi";
import AddAction from "./actions/AddAction";

function DiagnostiqueListeDetails() {
  const params = useParams();
  const navigation = useNavigate();
  const location = useLocation();

  const [editItem, setEditItem] = useState(null);
  const [seuil, setSeuil] = useState(0);
  const [opened, { open, close }] = useDisclosure(false);

  const { data, isLoading } = useGetDiagnostiqueQuery(
    parseInt(location?.state?.id)
  );
  // console.log({ location: location?.state?.id });

  const form = useForm({
    initialValues: {
      niveauId: "",
      seuil: "",
      diagnosticId: location?.state?.id,
    },

    validate: {},
  });

  const [deleteDiagnosticsNiveau, { isLoading: deleteLoading }] =
    useDeleteDiagnosticsNiveauMutation();
  const [editDiagnosticsNiveau, { isLoading: updateLoading }] =
    useEditDiagnosticsNiveauMutation();

  const [addDiagnosticsNiveau, { isLoading: addNiveauxLoadign }] =
    useAddDiagnosticsNiveauMutation();

  const { data: niveaux } = useGetNiveauxQuery();

  return (
    <div>
      <Group position="apart">
        <HeaderPage
          navigation={navigation}
          title={"Diagnostics " + location?.state?.title}
        />
        <Button
          disabled={isLoading || data?.data?.niveaux.length >= 3}
          onClick={open}
        >
          Ajouter niveau
        </Button>
      </Group>
      <Divider my={10} />

      {isLoading ? (
        <Loader />
      ) : (
        data?.data?.niveaux.map((item, index) => (
          <Paper withBorder key={index} mb={10} p={10}>
            <Group position="apart">
              <Text weight={500}>{item?.niveauId?.name}</Text>
              <Group spacing={40}>
                <Group>
                  <Text weight={500}>Seuil :</Text>
                  {editItem && editItem === item.id ? (
                    <TextInput
                      size="xs"
                      radius={20}
                      w={70}
                      type="number"
                      value={seuil}
                      onChange={(e) => setSeuil(e.target.value)}
                    />
                  ) : (
                    <Badge>{item?.seuil}</Badge>
                  )}
                </Group>
                <Group>
                  {editItem && editItem === item.id ? (
                    <ActionIcon
                      color="green"
                      onClick={async () => {
                        await EditAction(
                          editDiagnosticsNiveau,
                          { seuil: parseInt(seuil), id: parseInt(item?.id) },
                          ""
                        );
                        setEditItem(null);
                      }}
                      variant="filled"
                      loading={item.id === editItem && updateLoading}
                    >
                      <Check size={18} />
                    </ActionIcon>
                  ) : (
                    <ActionIcon
                      color="green"
                      onClick={() => {
                        setEditItem(item?.id);
                        setSeuil(parseInt(item.seuil));
                      }}
                    >
                      <Edit size={18} />
                    </ActionIcon>
                  )}
                  {/* <ActionIcon
                    color="red"
                    onClick={async () => {
                      await DeleteAction(deleteDiagnosticsNiveau, item?.id);
                      setEditItem(null);
                    }}
                    loading={item.id === editItem && deleteLoading}
                  >
                    <Trash size={18} />
                  </ActionIcon> */}
                  <Button
                    onClick={() =>
                      navigation(
                        // `${data?.data?.diagnostic?.id}/${item?.niveauId?.id}`,
                        `${item?.niveauId?.name}`,
                        {
                          state: {
                            niveauName: item?.niveauId?.name,
                            diagnosticId: data?.data?.diagnostic?.id,
                            niveauId: item?.niveauId?.id,
                          },
                        }
                      )
                    }
                    leftIcon={<Eye size={18} />}
                    size="xs"
                  >
                    {/* {console.log(data?.data)} */}
                    voir les exercices
                  </Button>
                </Group>
              </Group>
            </Group>
          </Paper>
        ))
      )}

      {/* Modal */}
      <Modal opened={opened} onClose={close} title={"Ajout niveaux"}>
        <form
          onSubmit={form.onSubmit(async (values) => {
            // return console.log(values, location?.state?.id);
            await AddAction(addDiagnosticsNiveau, values);
            close();
            form.reset();
          })}
        >
          <Select
            placeholder="Selection un niveau"
            withAsterisk
            sx={{ flex: 1 }}
            {...form.getInputProps("niveauId")}
            data={
              niveaux
                ? niveaux?.data
                    .filter(
                      (item) =>
                        item?.matiereId === data?.data?.diagnostic.matiereId
                    )
                    .map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))
                : []
            }
          />
          <TextInput
            {...form.getInputProps("seuil")}
            mt={10}
            type="number"
            placeholder="seuil"
          />

          <Divider my={20} />

          <Group position="right">
            <Button variant="light" onClick={close}>
              Annuler
            </Button>
            <Button type="submit" loading={addNiveauxLoadign}>
              Ajouter
            </Button>
          </Group>
        </form>
      </Modal>
    </div>
  );
}

export default DiagnostiqueListeDetails;
