import {
  ActionIcon,
  Box,
  Center,
  Group,
  Loader,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import React from "react";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
// import { useGetClassesQuery } from "../../../redux/features/classeApi";
// import { useGetNiveauxQuery } from "../../../redux/features/niveauApi";
import { useGetFicheQuery } from "../../../redux/features/ficheApi";
import { ArrowLeft } from "tabler-icons-react";
// import { useGetMatiereQuery } from "../../redux/features/matiereApi";

function FicheDetailsPage() {
  const params = useParams();
  const { data, isLoading } = useGetFicheQuery(params?.id);
  //   console.log({ data });

  const navigate = useNavigate();

  //   const { data: classes } = useGetClassesQuery();
  //   const { data: niveaux } = useGetNiveauxQuery();
  //   const { data: matieres } = useGetMatiereQuery();

  return (
    <Box>
      {isLoading ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <>
          <Group>
            <ActionIcon onClick={() => navigate(-1)}>
              <ArrowLeft />
            </ActionIcon>

            <Text>{data?.data?.name}</Text>
          </Group>

          <Paper p={5} withBorder my={10}>
            <Text>{data?.data?.objectifPedagogique}</Text>
          </Paper>

          <ReactQuill
            theme="snow"
            value={data?.data?.contenu}
            readOnly
            placeholder="Mettre le contenue de la fiche icci"
          />
          <Space h={40} />
        </>
      )}
    </Box>
  );
}

export default FicheDetailsPage;
