export const formateDate= (date)=>{
    const formated_date = new Date(date)?.toLocaleDateString(
        "fr-FR",
        {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        }
      )
    return formated_date;
}