import { useMemo, useState } from "react";
import { Table, ScrollArea, Group, Text, ActionIcon } from "@mantine/core";
import { Edit, Eye, Trash } from "tabler-icons-react";
import { sortData } from "../../../utils/tableActions";
import Th from "../../../components/Th";
import { useNavigate } from "react-router-dom";

export default function PayementParentTable({
  data,
  handleEdit,
  handleDelete,
}) {
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const navigation = useNavigate();

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search: "" }));
  };

  // console.log({ sortedData, data });

  useMemo(() => {
    setSortedData(data);
    // console.log(data);
  }, [data]);

  const rows = sortedData.map((row) => (
    <tr key={row?.id}>
      <td>
        <Group>
          {/* <Avatar color="blue" radius={"50%"}>
            {row?.firstName?.at(0)}
            {row?.lastName?.at(0)}
          </Avatar> */}
          <Text weight={600}>
            {new Date(row?.created_at).toLocaleString("fr-FR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              // second: "numeric",
            })}
          </Text>
        </Group>
      </td>
      {/* <td>{row?.payment_status}</td> */}
      <td>{row?.data?.amount} FCFA</td>
      <td>
        {new Date(row?.created_at)
          .toLocaleString("fr-FR", {
            month: "long",
          })
          .toUpperCase()}
      </td>
      <td>{row?.parent}</td>
      {/* <td>
        <Group position="right">
          <ActionIcon color="blue" onClick={() => navigation(`${row?.id}`)}>
            <Eye size={18} />
          </ActionIcon>
          <ActionIcon color="green" onClick={() => handleEdit(row?.id, row)}>
            <Edit size={18} />
          </ActionIcon>
          <ActionIcon onClick={() => handleDelete(`${row?.id}`)}>
            <Trash size={18} color="red" />
          </ActionIcon>
        </Group>
      </td> */}
    </tr>
  ));

  return (
    <ScrollArea>
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw={700}
        sx={{ tableLayout: "fixed" }}
        bg={"#fff"}
      >
        <thead>
          <tr>
            <Th
              sorted={sortBy === "date"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("date")}
            >
              date
            </Th>
            {/* <Th
              sorted={sortBy === "sourcePaiment"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("sourcePaiment")}
            >
              sourcePaiments
            </Th> */}
            <Th
              sorted={sortBy === "Montant"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("Montant")}
            >
              Montants
            </Th>
            <Th
              sorted={sortBy === "moisPaye"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("moisPaye")}
            >
              Mois paye
            </Th>
            <Th
              sorted={sortBy === "studentId"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("studentId")}
            >
              Parent
            </Th>
            {/* <th style={{ textAlign: "right" }}>action</th> */}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              {/* <td colSpan={Object.keys(data[0]).length}> */}
              <td>
                <Text weight={500} align="center">
                  Nothing found
                </Text>
              </td>
              {/* </td> */}
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}
