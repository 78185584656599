import React, { useState } from "react";
import { useGetAppelsWeeklyQuery } from "../../redux/features/appelApi";
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Loader,
  Pagination,
  Select,
  TextInput,
  Title,
} from "@mantine/core";
import AppelTable from "./components/AppelTable";
import { useNavigate } from "react-router-dom";
import { useGetCoachBasicInfoQuery } from "../../redux/features/coachApi";

const AppelPage = () => {
  const navigation = useNavigate();
  let [limit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const [dates, setDates] = useState({ date_start: "", date_end: "" });
  const [date, setDate] = useState("");
  const [coachId, setCoachId] = useState(null);
  const { data: coachs, isLoading: isCoachLoading } =
    useGetCoachBasicInfoQuery();

  const is_fetching_filter =
    dates.date_start !== "" ||
    dates.date_end !== "" ||
    date ||
    coachId !== null;

  // const {data:appels,isLoading} = useGetAppelsQuery();
  const {
    data: filtered_appel,
    isLoading,
    isFetching,
  } = useGetAppelsWeeklyQuery(
    { ...dates, date, coachId },
    {
      is_fetching_filter,
    }
  );
  console.log({ coachId });

  // Pagination Configuration
  const currentData = filtered_appel?.data;

  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const totalPages = Math.ceil(currentData?.length / limit);
  const currentDataPage = currentData?.slice(startIndex, endIndex);

  return (
    <Box>
      <Title order={3} mb={5}>
        Liste des appels{" "}
      </Title>

      <Group position="end">
        {/* <TextInput placeholder="Recherche" /> */}

        <Button onClick={() => navigation("create")}>Ajouter des appels</Button>
      </Group>

      <Divider my={20} />
      <Flex justify="space-between" mb={10}>
        <Group>
          <Select
            placeholder={`${dates.date_start !== "" ? "" : "Aujourd'hui"}`}
            data={[
              { value: "day", label: "Aujourd'hui" },
              { value: "week", label: "Semaine en cours" },
              { value: "month", label: "Mois en cours" },
            ]}
            clearable
            value={date}
            onChange={(e) => {
              setDate(e);
              setDates({ date_start: "", date_end: "" });
              setCurrentPage(1);
            }}
          />
          <Select
            searchable
            placeholder="selectionner un coach"
            clearable
            data={
              coachs?.data?.map((item) => ({
                label: item?.fullName,
                value: item?.id,
              })) ?? []
            }
            onChange={(e) => {
              setCoachId(e);
            }}
          />
        </Group>

        <Group>
          <TextInput
            type="date"
            placeholder="Date de debut"
            onChange={(e) => {
              setDates({ ...dates, date_start: e.target.value });
              setDate("");
              setCurrentPage(1);
            }}
            value={dates?.date_start ?? ""}
          />
          <TextInput
            type="date"
            placeholder="Date de fin"
            onChange={(e) => {
              setDates({ ...dates, date_end: e.target.value });
              setDate("");
              setCurrentPage(1);
            }}
            value={dates?.date_end ?? ""}
            disabled={!dates.date_start}
          />
        </Group>
      </Flex>

      {isLoading || isFetching ? (
        <Loader />
      ) : (
        <Box mb={20}>
          <AppelTable data={currentDataPage} />

          <Group position="right" mt={10}>
            <Pagination
              value={currentPage}
              onChange={setCurrentPage}
              total={totalPages}
            />
          </Group>
        </Box>
      )}
    </Box>
  );
};

export default AppelPage;
