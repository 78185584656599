import { Group, Paper, Text, ThemeIcon, SimpleGrid, Grid, Loader } from '@mantine/core';
import { IconArrowUpRight, IconArrowDownRight, IconEqualDouble } from '@tabler/icons-react';
import { useGetCoachsGlobalMetricsQuery } from '../../../redux/features/coachApi';
import { Equal } from 'tabler-icons-react';
import { IconEqual } from '@tabler/icons-react';


export default function CoachsKpisStats() {

    const {data:metrics, isLoading} = useGetCoachsGlobalMetricsQuery()
    return (
    <div >
        {
            isLoading ? (
                <Loader />
            ):
            (
                <Grid justify="" align="stretch">
                    {
                        metrics?.data?.map((data) => {
                            const rate = data.count > data.previous
                            ? 'increase'
                            : data.count < data.previous
                            ? 'decrease': 'equal'
                            
                            const DiffIcon = rate ==="increase" ? 
                                IconArrowUpRight
                                :rate==="decrease" ? 
                                    IconArrowDownRight :IconEqual

                            return (
                                <Grid.Col span={3} >
                                    <Paper className='' withBorder p="md" radius="md" key={data.title}>
                                        <Group justify="apart">
                                        <div>
                                            <Text c="dimmed" tt="uppercase" fw={700} fz="xs" >
                                            {data.title}
                                            </Text>
                                            <Text fw={700} fz="xl">
                                            {data.count}
                                            </Text>
                                        </div>
                                        <ThemeIcon
                                            color="gray"
                                            variant="light"
                                            style={{
                                            // color: data.count > data.previous ? 'green' : 'red',
                                                color : rate === "increase"?
                                                    'green'
                                                    : rate === "decrease" ?
                                                    'red' : 'blue'
                                            }}
                                            size={38}
                                            radius="md"
                                        >
                                            <DiffIcon  size="1.8rem" stroke={1.5} />
                                        </ThemeIcon>
                                        </Group>
                                        {/* <Text c="dimmed" fz="sm" mt="md"> */}
                                        <Text component="span" c={rate ==="increase" ? 'teal' : rate==="decrease" ? 'red': 'blue'} fw={700}>
                                            <span>{data.growth_rate}% </span>
                                            <DiffIcon size="1rem" stroke={1.5} />
                                            <span> preced : {data.previous}</span>
                                        </Text>
                                        {/* </Text> */}
                                    </Paper>
                                </Grid.Col>
                            )
                        })        
                    }
                </Grid>
            )
        }
     
    </div>
  );
}