import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const noteApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getNotes: build.query({
      query: () => API.NOTES_API.GETALL(),
      providesTags: ["Notes"],
    }),
    getNotesGroupeByClasse: build.query({
      query: () => API.NOTES_API.GETALL_GROUP_BY_CLASSE(),
      providesTags: ["Notes"],
    }),
    getDiagnosticStudentsListeByDiagnostic: build.query({
      query: (id) => API.NOTES_API.GETALL_DIAGNOSTIC_STUDENT_LIST(id),
      providesTags: ["Notes"],
    }),
    getDiagnosticStudentsListeByDiagnosticByStudent: build.query({
      query: ({ diagnosticId, studentId }) =>
        API.NOTES_API.GETALL_DIAGNOSTIC_STUDENT_LIST_BY_STUDENT(
          diagnosticId,
          studentId
        ),
      providesTags: ["Notes"],
    }),
    getDiagnosticNoteByStudent: build.query({
      query: (id) => API.NOTES_API.GETALL_DIAGNOSTIC_NOTES_STUDENT(id),
      providesTags: ["Notes"],
    }),
    addNotes: build.mutation({
      query({ body, token }) {
        return {
          url: API.NOTES_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Notes"],
    }),
    EditNotes: build.mutation({
      query({ body, token }) {
        return {
          url: API.NOTES_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Notes"],
    }),
    deleteNotes: build.mutation({
      query(id) {
        return {
          url: API.NOTES_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Notes"],
    }),
  }),
});

export const {
  useGetNotesQuery,
  useGetNotesGroupeByClasseQuery,
  useGetDiagnosticStudentsListeByDiagnosticQuery,
  useGetDiagnosticStudentsListeByDiagnosticByStudentQuery,
  useGetDiagnosticNoteByStudentQuery,
  useAddNotesMutation,
  useEditNotesMutation,
  useDeleteNotesMutation,
} = noteApi;
