// eslint-disable-next-line no-extend-native
export default Array.prototype.formatArray = function (
  id = "id",
  name = "name"
) {
  return this.map((item) => ({
    value: item[id]?.toString(),
    label: item[name],
  }));
};
