import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import users from "../reducers/users";
// import classes from "./reducers/classeSlice";
// import villes from "./reducers/villeSlice";
// import userIdReducer from "./features/userSlice";
import { baseApi } from "./apiMananger";
import authSlice from "./reducers/userSlice";

const persistConfig = {
  key: process.env.REACT_APP_RTK_KEY,
  storage,
};

const rootReducer = combineReducers({
  user: persistReducer(persistConfig, authSlice),
  [baseApi.reducerPath]: baseApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([baseApi.middleware]);
  },
  devTools: true,
});

export const persistor = persistStore(store);
