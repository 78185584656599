import { Navigate, Outlet } from "react-router-dom";
import Template from "../layouts/Template";
import getCurrentUser from "../hooks/getCurrentUser";

const ProtectedRoutes = () => {
  const user = getCurrentUser();
  return user?.status ? (
    user?.status === "ADMIN" ? (
      <Template>
        <Outlet />
      </Template>
    ) : (
      <Navigate to={"/partenaire"} />
    )
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ProtectedRoutes;
