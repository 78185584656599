import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const waveApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getSoldeWave: build.query({
      query: () => API.WAVE.GET(),
      providesTags: ["Waves"],
    }),
  }),
});

export const { useGetSoldeWaveQuery } = waveApi;
