import { Badge, Box, Divider, Flex, Text } from "@mantine/core";
import ReactQuill from "react-quill";

const EperuveNullType = ({epreuve}) => {
    return ( 
        <Box>
            {
                console.log(epreuve)
            }
            <Flex
                mih={50}
                gap="md"
                justify="space-between"
                align="center"
                direction="row"
                wrap="wrap"
            >
                <Text size="md">{epreuve?.niveauId?.name} : {epreuve?.titre}/</Text>
                <Badge
                    size="xl"
                    color={epreuve?.archived?"red":"green"}
                >
                    {epreuve?.archived?"Deactive":"Actif"}
                </Badge>
            </Flex>
            <Divider />
            <Box>
                <Text>Sujet :</Text>
                <ReactQuill

                    theme="snow"
                    value={epreuve?.sujet }
                    readOnly
                />
            </Box>
            <Box>
                <Text>Correction :</Text>
                <ReactQuill

                    theme="snow"
                    value={epreuve?.correction }
                    readOnly
                />
            </Box>
        </Box>
     );
}
 
export default EperuveNullType;