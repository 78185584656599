import {
  ActionIcon,
  Box,
  Flex,
  Group,
  NumberInput,
  Paper,
  Textarea,
  TextInput,
} from "@mantine/core";
import React from "react";
import { X } from "tabler-icons-react";

function AudioRecordingWithTimerForm({ index, form }) {
  return (
    <Paper mb={20} p={10} shadow="xs">
      <Group noWrap position="apart">
        <Box style={{ flex: 1 }}>
          <TextInput
            // style={{ flex: 1 }}
            {...form.getInputProps(`epreuves.${index}.titre`)}
            placeholder="Mettre le titre"
            mb={10}
          />
          <Flex gap={10}>
            <Textarea
              style={{ flex: 1 }}
              {...form.getInputProps(`epreuves.${index}.correction_dp`)}
              placeholder="Mettre le text ou le groupe de mot a lire ici..."
              // mb={10}
            />
            <NumberInput
              placeholder="minutes"
              defaultValue={1}
              label={"Nombre de minutes"}
              type="number"
              {...form.getInputProps(`epreuves.${index}.choices`)}
              style={{ width: 200 }}
            />
          </Flex>
        </Box>
        <ActionIcon
          color="red"
          onClick={() => form.removeListItem(`epreuves`, index)}
        >
          <X size="1rem" />
        </ActionIcon>
      </Group>
    </Paper>
  );
}

export default AudioRecordingWithTimerForm;
