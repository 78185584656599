import React from "react";
import { useGetPayementsQuery } from "../../redux/features/payementApi";
import PayementTable from "./components/PayementTable";
import { Button, Center, Group, Loader, Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

const PaiementElevePage = () => {
  const { data, isLoading } = useGetPayementsQuery();

  const [opened, { open, close }] = useDisclosure();

  console.log({ data });

  return (
    <div>
      <Group position="apart" mb={20}>
        <Title order={3}>Eleves Paiements</Title>
        <Button>Ajouter un paiement</Button>
      </Group>
      {isLoading ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <PayementTable data={data ? data?.data : []} />
      )}

      <Modal opened={opened} onClose={close}>
        <form></form>
      </Modal>
    </div>
  );
};

export default PaiementElevePage;
