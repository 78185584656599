import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const ecoleApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getEcoles: build.query({
      query: () => API.ECOLES_API.GETALL(),
      providesTags: ["Ecoles"],
    }),
    addEcoles: build.mutation({
      query({ body, token }) {
        return {
          url: API.ECOLES_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Ecoles"],
    }),
    EditEcoles: build.mutation({
      query({ body, token }) {
        return {
          url: API.ECOLES_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Ecoles"],
    }),
    deleteEcoles: build.mutation({
      query(id) {
        return {
          url: API.ECOLES_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Ecoles"],
    }),
  }),
});

export const {
  useGetEcolesQuery,
  useAddEcolesMutation,
  useEditEcolesMutation,
  useDeleteEcolesMutation,
} = ecoleApi;
