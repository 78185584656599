import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const payementApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getPayements: build.query({
      query: () => API.PAYEMENTS_API.GETALL(),
      providesTags: ["Paiement"],
    }),
    getPayementsParents: build.query({
      query: () => API.PAYEMENTS_API.GETALLBYPARENT(),
      providesTags: ["Paiement"],
    }),
    getPayement: build.query({
      query: (id) => API.PAYEMENTS_API.GET(id),
      providesTags: ["Paiement"],
    }),
    addPayements: build.mutation({
      query({ body, token }) {
        return {
          url: API.PAYEMENTS_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Paiement"],
    }),
    EditPayements: build.mutation({
      query({ body, token }) {
        return {
          url: API.PAYEMENTS_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Epreuves"],
    }),
    deletePayements: build.mutation({
      query(id) {
        return {
          // url: API.PAYEMENTS_API.DELETE(id),
          url: `epreuves/diagnostic/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Epreuves"],
    }),
  }),
});

export const {
  useGetPayementQuery,
  useGetPayementsQuery,
  useGetPayementsParentsQuery,
  useAddPayementsMutation,
  useDeletePayementsMutation,
} = payementApi;
