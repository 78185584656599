import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

export const parentApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getParents: build.query({
      // query: () => API.PARENTS_API.GETALL(),
      query: () => "parents/?type=CONFIRMED",
      providesTags: ["Parents"],
    }),
    getMessage: build.query({
      // query: () => API.PARENTS_API.GETALL(),
      query: () => "parents/contacts/",
      providesTags: [""],
    }),
    getProspects: build.query({
      query: () => API.PARENTS_API.GETPROSPECTS(),
      providesTags: ["Parents"],
    }),
    getProspectsSenAssurances: build.query({
      query: () => API.PARENTS_API.GETPROSPECTSSENASSURANCE(),
      providesTags: ["Parents"],
    }),
    convertirProspect: build.query({
      query: (id) => {
        return {
          url: API.PARENTS_API.CONVERTPROSPECTS(id),
          method: "PUT",
          body: {
            type: "CONFIRMED",
          },
        };
      },
      // API.PARENTS_API.CONVERTPROSPECTS(id),
      invalidatesTags: ["Parents"],
    }),
    addParents: build.mutation({
      query({ body, token }) {
        return {
          url: API.PARENTS_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Parents"],
    }),
    // changeStatus: build.mutation({
    //   query({ body }) {
    //     return {
    //       url: API.PARENTS_API.CHANGESTATUS(body?.id),
    //       method: "PUT",
    //       body,
    //     };
    //   },
    // query(id) {
    //   return {
    //     url: API.PARENTS_API.CHANGESTATUS(id),
    //     method: "PUT",
    //     // headers: {
    //     //   Accept: "application/json",
    //     //   "Content-Type": "application/json",
    //     //   "X-CSRFToken": token,
    //     // },
    //     body:{
    //       type:"CONFIRMED"
    //     }
    //     ,
    //   };
    // },
    //   invalidatesTags: ["Parents"],
    // }),
    EditParents: build.mutation({
      query({ body, token }) {
        return {
          url: API.PARENTS_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Parents"],
    }),
    deleteParents: build.mutation({
      query(id) {
        return {
          url: API.PARENTS_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Parents"],
    }),
  }),
});

export const {
  useGetParentsQuery,
  useAddParentsMutation,
  useEditParentsMutation,
  useDeleteParentsMutation,
  useGetProspectsQuery,
  useConvertirProspectQuery,
  useGetProspectsSenAssurancesQuery,
  useGetMessageQuery,
} = parentApi;
