import { keys } from "@mantine/utils";

export function filterData(data, search) {
  const query = search.toLowerCase().trim();
  return data.filter((item) => {
    return keys(data[0]).some(({ key }) => {
      console.log(item[key] === undefined ? false : item[key], key);
      return item[key] === undefined
        ? false
        : item[key]?.toLowerCase().includes(query);
    });
  });
}

export function sortData(data, payload) {
  const { sortBy } = payload;

  // if (!sortBy) {
  //   return filterData(data, payload.search);
  // }

  // console.log({ sortBy });

  // return filterData(
  //   [...data].sort((a, b) => {
  //     if (payload.reversed) {
  //       return b[sortBy]?.localeCompare(a[sortBy]);
  //     }

  //     return a[sortBy]?.localeCompare(b[sortBy]);
  //   }),
  //   payload.search
  // );
  const convert = sortBy.split(".");
  if (convert.legth > 0) {
    return [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy]?.localeCompare(a[sortBy]);
      }

      return a[sortBy]?.localeCompare(b[sortBy]);
    });
  }

  return [...data].sort((a, b) => {
    if (payload.reversed) {
      return b[sortBy]?.localeCompare(a[sortBy]);
    }

    return a[sortBy]?.localeCompare(b[sortBy]);
  });
}
