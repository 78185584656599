import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const villeApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getVilles: build.query({
      query: () => API.VILLES_API.GETALL(),
      providesTags: ["Villes"],
    }),
    addVilles: build.mutation({
      query({ body, token }) {
        return {
          url: API.VILLES_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Villes"],
    }),
    EditVilles: build.mutation({
      query({ body, token }) {
        return {
          url: API.VILLES_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Villes"],
    }),
    deleteVilles: build.mutation({
      query(id) {
        return {
          url: API.VILLES_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Villes"],
    }),
  }),
});

export const {
  useGetVillesQuery,
  useAddVillesMutation,
  useEditVillesMutation,
  useDeleteVillesMutation,
} = villeApi;
