import React from "react";

function Text({
  children,
  size,
  weight,
  style,
  onClick,
  p,
  m,
  mt,
  mb,
  my,
  pt,
  pb,
  py,
  mx,
  mr,
  ml,
  px,
  pl,
  pr,
  color,
}) {
  return (
    <div
      style={{
        padding: p,
        margin: m,
        marginTop: mt ?? my,
        marginBottom: mb ?? my,
        marginLeft: ml ?? mx,
        marginRight: mr ?? mx,
        paddingTop: pt ?? py,
        paddingBottom: pb ?? py,
        paddingLeft: pl ?? px,
        paddingRight: pr ?? px,
        fontSize: size ?? 13,
        fontWeight: weight ?? 500,
        color: color,
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default Text;
