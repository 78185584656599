import { useParams } from "react-router-dom";
import Flex from "../../../../components/Flex";

const DiagnostiqueQuizzDetails = () => {

    const {id} = useParams();
    const diagnostiques =JSON.parse(localStorage.getItem('diagnostiques'))||[];
    const diagnostique = diagnostiques.find(diagnostique => diagnostique.id == id)

    return ( 
        <div className="px-5">
            <Flex position={"apart"}>
                <p>Matiere : {diagnostique.matiereId}</p>
                <p>Niveau : {diagnostique.niveauId}</p>
                <p>Classe : {diagnostique.classeId}</p>
            </Flex>
            <h3>Question : </h3>
            <p>{diagnostique.question}</p>
            <h3>Response  : </h3>
            <Flex position={"apart"}>
            <p>1- {diagnostique.reponse1}</p>
            <p>2- {diagnostique.reponse2}</p>
            <p>3- {diagnostique.reponse3}</p>
            <p>4- {diagnostique.reponse4}</p>
            </Flex>
        </div>
     );
}
 
export default DiagnostiqueQuizzDetails;