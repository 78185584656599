import { ActionIcon, Group, Paper, TextInput } from "@mantine/core";
import React from "react";
import { X } from "tabler-icons-react";

function AlphabetForm({ form, correction_dp, index }) {
  return (
    <Paper mb={20} p={10} shadow="xs">
      <Group noWrap position="apart">
        <TextInput
          style={{ flex: 1 }}
          {...form.getInputProps(`epreuves.${index}.titre`)}
          placeholder="Mettre le titre de l'epreuve"
          // mb={10}
        />
        <ActionIcon
          color="red"
          onClick={() => {
            console.log("lalal", index);
            form.removeListItem(`epreuves`, index);
          }}
        >
          <X size="1rem" />
        </ActionIcon>
      </Group>
      <Group mt={10}>
        {correction_dp?.split("-").length > 0
          ? correction_dp?.split("-").map((lettre, index) => (
              <Paper withBorder key={index} py={5} px={10}>
                {lettre}
              </Paper>
            ))
          : null}
      </Group>
    </Paper>
  );
}

export default AlphabetForm;
