import { useMantineTheme } from "@mantine/core";

function useTheming() {
  const theme = useMantineTheme();

  // const env = process.env.REACT_APP_ENV ?? "production";
  const env =
    document.location?.origin === "https://genimi-web.fewnu.app"
      ? "production"
      : "recette";
  // console.log({ env: process.env.NODE_ENV });
  return {
    bg: env === "recette" ? theme.colors.teal[5] : theme.colors.blue[5],
    color: env === "recette" ? theme.colors.teal[5] : theme.colors.blue[5],
    radius: 10,
  };
}

export default useTheming;
