import {
  Box,
  Divider,
  Group,
  Image,
  Loader,
  Paper,
  SimpleGrid,
  Space,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import React from "react";
import { StatsSecond } from "./components/StatsSecond";
import { useGetKpisQuery } from "./api/features/queriesApi";
import { useGetSoldeWaveQuery } from "../../redux/features/waveApi";
import { School, Users } from "tabler-icons-react";
import { Navigate, useNavigate } from "react-router-dom";

function DashboardPage() {
  const { data, isLoading } = useGetKpisQuery();
  // const { data: soldeWave, isLoading: soldeLoading } = useGetSoldeWaveQuery();
  const navigation = useNavigate()

  const navigateToPath = (to) => {
    navigation(`/admin/${to.toLowerCase()}`);
  };

  return (
    <Box pb={30}>
      {isLoading ? (
        <Loader />
      ) : (
        data?.data.map((item, index) =>
          item?.type === "default" ? (
            <SimpleGrid
              cols={3}
              breakpoints={[
                { maxWidth: "lg", cols: 3 },
                { maxWidth: "md", cols: 3 },
                { maxWidth: "sm", cols: 2 },
                { maxWidth: "xs", cols: 1 },
              ]}
              key={index}
            >
              <Paper p="md" radius="md" key={index} bg={"#1dc4ff"} shadow="sm">
                <Group position="apart">
                  <div>
                    <Text tt="uppercase" fw={700} fz="lg" color="#fff">
                      Wave
                    </Text>
                    <Text fw={700} fz="xl" mt={10} color="#fff" weight={800}>
                      {/* {soldeLoading ? "loading" : soldeWave.solde.amount} */}
                    </Text>
                  </div>
                  <Image
                    src={"https://www.wave.com/img/nav-logo.png"}
                    width={100}
                  />
                </Group>
              </Paper>

              {item?.data.map((item, index) => (
                <Paper p="md" radius="md" key={index} shadow="sm"
                style={{ cursor: "pointer" }}
                onClick={()=>navigateToPath(item.name)}
                >
                  <Group position="apart">
                    <div>
                      <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
                        {item.name}
                      </Text>
                      <Text fw={700} fz="xl" mt={10}>
                        {item.value}
                      </Text>
                    </div>
                    <ThemeIcon
                      color="gray"
                      variant="light"
                      sx={(theme) => ({
                        // color: stat.diff > 0 ? theme.colors.teal[6] : theme.colors.red[6],
                      })}
                      size={38}
                      radius="md"
                    >
                      {item.name.toUpperCase() === "COACHS" ? (
                        <Users size="1.8rem" />
                      ) : (
                        <School size="1.8rem" />
                      )}
                    </ThemeIcon>
                  </Group>
                  {/* <Text c="dimmed" fz="sm" mt="md">
                    {stat?.description}
                  </Text> */}
                </Paper>
              ))}
            </SimpleGrid>
          ) : (
            <div key={index}>
              <Space h={30} />

              <Divider
                label={<Title order={4}>{item?.type} status</Title>}
                mb={10}
              />

              <StatsSecond
                data={item?.data.map(({ name, value, description ,path}) => ({
                  title: name,
                  value,
                  description,
                  path
                }))}
              />
            </div>
          )
        )
      )}
    </Box>
  );
}

export default DashboardPage;
