import { useState } from "react";
import { useForm } from "react-hook-form";
import Flex from "../../../components/Flex";
import InputSelect from "../../../components/InputSelect";
import Text from "../../../components/Text";
import { Outlet, useNavigate } from "react-router-dom";
import Divider from "../../../components/Divider";
import TabViews from "../../../components/TabViews";

const DiagnostiqueParentListe = () => {
    const { register, setError, formState: { errors }, handleSubmit } = useForm();
    const [isLoading,setIsLoading] = useState(false)
    const [typeDiagnostique, setTypeDiagnostique] = useState("")
    const navigation = useNavigate();
    const tabs = [
        { label: "Lecture", path: "" },
        { label: "Quizz", path: "quizz" },
        { label: "Maths", path: "maths" },
      ];

    return ( 
        <div>
        <Text size={20}>DiagnostiquePage</Text>
        <TabViews tabs={tabs} />
        <Divider mb={20} />
        <div className="">
          <Outlet />
        </div>
      </div>
     );
}
 
export default DiagnostiqueParentListe;
