import React, { useState } from "react";
import Flex from "../../components/Flex";
import {
  useAddDiagnostiquesMutation,
  useGetDiagnostiquesQuery,
} from "../../redux/features/diagnostiqueApi";
import { useGetClassesQuery } from "../../redux/features/classeApi";
import { useGetNiveauxQuery } from "../../redux/features/niveauApi";
import { useGetMatiereQuery } from "../../redux/features/matiereApi";
import {
  ActionIcon,
  Button,
  Divider,
  Group,
  Loader,
  Modal,
  Select,
  Space,
  TextInput,
} from "@mantine/core";
import DiagnostiqueTable from "./components/DiagnostiqueTable";
import { useForm } from "@mantine/form";
import AddAction from "./actions/AddAction";
import { Trash } from "tabler-icons-react";
import { useDisclosure } from "@mantine/hooks";
import DeleteAction from "./actions/DeleteAction";
import { useDeleteDiagnostiquesMutation } from "./api/features/diagnostiqueApi";
import toast from "react-hot-toast";

function DiagnostiqueListe() {
  const { data, isLoading } = useGetDiagnostiquesQuery();
  const [addDiagnostiques, { isLoading: isLoadinAaddDiagnostic }] = useAddDiagnostiquesMutation();
  // const [editDiagnostiques] = useEditDiagnostiquesMutation();
  const [deleteDiagnostiques] = useDeleteDiagnostiquesMutation();
  const [opened, { open, close }] = useDisclosure(false);
  const { data: classes } = useGetClassesQuery();
  const { data: niveaux } = useGetNiveauxQuery();
  const { data: matieres } = useGetMatiereQuery();

  // const [opened, setOpened] = useState(false);
  const [query, setQuery] = useState("");
  const [itemEdited, setItemEdited] = useState(null);

  const form = useForm({
    initialValues: {
      classeId: "",
      matiereId: "",
      type_diagnostique: "",
      niveaux: [{ seuil: 0, niveauId: "" }],
    },

    validate: {},
  });

  // console.log({ niveaux });

  // const handleEdit = async () => {
  //   setEditLoadin(true);
  //   const newData = {
  //     ...form,
  //     matiereId: parseInt(form?.matiereId?.value),
  //     classeId: parseInt(form?.classeId?.value),
  //     niveauId: parseInt(form?.niveauId?.value),
  //   };
  //   try {
  //     await editDiagnostiques({
  //       body: { ...newData, id: itemEdited?.id },
  //     });
  //     setEditLoadin(false);
  //     setItemEdited(null);
  //     //   console.log({ res });
  //   } catch (error) {
  //     console.log({ error });
  //     setEditLoadin(false);
  //   }
  // };

  // console.log({ data });
  const typeDiagnostiqueOptions = data?.type_diagnostique
    ? data.type_diagnostique.map((item) => ({
      label: item?.name,
      value: item?.id,
    }))
    : [];

  return (
    <div>
      <Flex position={"apart"} mb={20}>
        {/* <TextInput
          placeholder={"Rechercher un diagnostique"}
          onChange={(value) => setQuery(value.toLowerCase())}
          value={query}
        /> */}
        <Button
          onClick={() => {
            setItemEdited(null);
            open(true);
          }}
        >
          Ajouter un diagnostique
        </Button>
      </Flex>

      {isLoading ? (
        <Loader />
      ) : (
        <DiagnostiqueTable
          data={data ? data?.data : [{}]}
          handleAdd={() => { }}
          handleDelete={async (id) =>
            await DeleteAction(deleteDiagnostiques, id)
          }
        />
      )}

      <Space h={30} />

      {/* My modal */}
      <Modal
        opened={opened}
        onClose={close}
        title={itemEdited ? "Edit niveaux" : "Add niveaux"}
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            // const response = await AddAction(addDiagnostiques, values);
            // console.log(response)
            try {
              const response = await addDiagnostiques({ body: values });
              if (response?.data?.status === 400) {
                close();
                form.reset();
                return toast.error("Diagnostic Deja cree");
              }
              close();
              form.reset();
              toast.success("Creation diagnostic reussie");
            } catch (error) {
              toast.error("Echec de la creation du diagnostic");
              close();
              form.reset();
            }
            // close();
            // form.reset();
          })}
        >
          <Select
            placeholder="Matiere"
            withAsterisk
            sx={{ flex: 1 }}
            {...form.getInputProps("matiereId")}
            data={
              matieres
                ? matieres?.data.map((item) => ({
                  label: item?.name,
                  value: item?.id,
                }))
                : []
            }
            mb={10}
          />

          <Select
            placeholder="classes"
            mb={10}
            withAsterisk
            sx={{ flex: 1 }}
            {...form.getInputProps("classeId")}
            data={
              classes
                ? classes?.data.map((item) => ({
                  label: item?.name,
                  value: item?.id,
                }))
                : []
            }
          />

          <Select
            placeholder="Type de diagnostique"
            withAsterisk
            sx={{ flex: 1 }}
            {...form.getInputProps("type_diagnostique")}
            data={[
              { label: "Demo", value: "DEMO" },
              { label: "Normal", value: "NORMAL" },
              ...typeDiagnostiqueOptions
            ]}
            mb={10}
          />

          {form.values.matiereId && (
            <>
              <Divider label="Liste des niveaux" />
              {form.values.niveaux.map((item, index) => (
                <Group key={index} mt="xs">
                  <Select
                    label="Selectionner le niveau"
                    placeholder="John Doe"
                    withAsterisk
                    sx={{ flex: 1, width: "100%" }}
                    {...form.getInputProps(`niveaux.${index}.niveauId`)}
                    data={
                      niveaux
                        ? niveaux?.data
                          .filter(
                            (item) =>
                              item?.matiereId === form.values.matiereId
                          )
                          .map((item) => ({
                            label: item?.name,
                            value: item?.id,
                          }))
                        : []
                    }
                  />
                  <TextInput
                    label="Seuil"
                    type="number"
                    {...form.getInputProps(`niveaux.${index}.seuil`)}
                    w={70}
                  />
                  <ActionIcon
                    color="red"
                    onClick={() => form.removeListItem("niveaux", index)}
                  >
                    <Trash size="1rem" />
                  </ActionIcon>
                </Group>
              ))}
              {form.values.niveaux.length === 3 ? null : (
                <Button
                  onClick={() =>
                    form.insertListItem("niveaux", { niveauId: "", seuil: 21 })
                  }
                  mt={10}
                >
                  Ajout niveau
                </Button>
              )}
            </>
          )}

          <Divider my={20} />

          <Group position="right">
            <Button variant="light" onClick={close}>
              Annuler
            </Button>
            <Button type="submit" disabled={isLoadinAaddDiagnostic}>
              {
                isLoadinAaddDiagnostic ? "En cours..." : "Ajouter"
              }
              {console.log(isLoadinAaddDiagnostic)}
            </Button>
          </Group>
        </form>
      </Modal>
    </div>
  );
}

export default DiagnostiqueListe;
