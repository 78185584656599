import { Table } from "@mantine/core";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetDiagnostiqueTentavivesByElevesGroupeByTententativeQuery } from "../../../redux/features/diagnostiqueApi";
import HeaderPage from "../../../components/HeaderPage";

function EleveDiagnostiqueNotePage() {
  const params = useParams();
  const navigation = useNavigate();
  const location = useLocation();
  const { data: tentativeData, isLoading: istentativeLoading } =
    useGetDiagnostiqueTentavivesByElevesGroupeByTententativeQuery(
      {
        eleveId: parseInt(params?.id),
        diagnosticId: parseInt(location?.state?.diagnosticId),
      },
      { skip: !location?.state?.diagnosticId && !params?.id }
    );

  //   console.log({ tentativeData });
  return (
    <div>
      <HeaderPage
        navigation={navigation}
        title={`Detailes diagnostic du ${location?.state?.date}`}
      />
      <div style={{ margin: "20px 0" }}></div>
      <Table>
        <thead>
          <tr>
            <th>Niveau</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {istentativeLoading ? (
            "Chargement en cours"
          ) : tentativeData?.data ? (
            <>
              {/* NIveau 1 */}
              <tr>
                <td
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    textAlign: "center",
                  }}
                >
                  Niveau 1
                </td>
                <td>
                  <Table>
                    <thead>
                      <tr>
                        <th>Dates</th>
                        <th>notes</th>
                        <th>baremes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tentativeData?.data[location?.state?.key]?.notes["1"]
                        ?.data.length > 0
                        ? tentativeData?.data[location?.state?.key]?.notes[
                            "1"
                          ]?.data?.map((note, index) => (
                            <tr>
                              <td>
                                {" "}
                                {new Date(note?.created_at).toLocaleDateString(
                                  "fr-FR",
                                  {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )}
                              </td>
                              <td>{note?.note}</td>
                              <td>{note?.bareme}</td>
                            </tr>
                          ))
                        : "Pas de note pour ce niveau"}
                    </tbody>
                  </Table>
                </td>
              </tr>

              {/* Niveau 2 */}
              <tr>
                <td
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    textAlign: "center",
                  }}
                >
                  Niveau 2
                </td>
                <td>
                  <Table>
                    <thead>
                      <tr>
                        <th>Dates</th>
                        <th>notes</th>
                        <th>baremes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tentativeData?.data[location?.state?.key]?.notes["2"]
                        ?.data.length > 0
                        ? tentativeData?.data[location?.state?.key]?.notes[
                            "1"
                          ]?.data?.map((note, index) => (
                            <tr>
                              <td>
                                {" "}
                                {new Date(note?.created_at).toLocaleDateString(
                                  "fr-FR",
                                  {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )}
                              </td>
                              <td>{note?.note}</td>
                              <td>{note?.bareme}</td>
                            </tr>
                          ))
                        : "Pas de note pour ce niveau"}
                    </tbody>
                  </Table>
                </td>
              </tr>

              {/* Niveau 3 */}
              <tr>
                <td
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    textAlign: "center",
                  }}
                >
                  Niveau 3
                </td>
                <td>
                  <Table>
                    <thead>
                      <tr>
                        <th>Dates</th>
                        <th>notes</th>
                        <th>baremes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tentativeData?.data[location?.state?.key]?.notes["3"]
                        ?.data.length > 0
                        ? tentativeData?.data[location?.state?.key]?.notes[
                            "1"
                          ]?.data?.map((note, index) => (
                            <tr>
                              <td>
                                {" "}
                                {new Date(note?.created_at).toLocaleDateString(
                                  "fr-FR",
                                  {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )}
                              </td>
                              <td>{note?.note}</td>
                              <td>{note?.bareme}</td>
                            </tr>
                          ))
                        : "Pas de note pour ce niveau"}
                    </tbody>
                  </Table>
                </td>
              </tr>
            </>
          ) : null}
        </tbody>
      </Table>
    </div>
  );
}

export default EleveDiagnostiqueNotePage;
