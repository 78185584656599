import React, { useState } from "react";
import Box from "../../components/Box";
import Flex from "../../components/Flex";
import Text from "../../components/Text";
import InputText from "../../components/InputText";
import Button from "../../components/Button";
import Divider from "../../components/Divider";
import { Eye, Pencil, Trash } from "tabler-icons-react";
import Modal from "../../components/Modal";
import {
  useAddVillesMutation,
  useDeleteVillesMutation,
  useEditVillesMutation,
  useGetVillesQuery,
} from "../../redux/features/villeApi";

const VillePage = () => {
  const { data, isLoading } = useGetVillesQuery();
  const [addVilles] = useAddVillesMutation();
  const [editVilles] = useEditVillesMutation();
  const [deleteVilles] = useDeleteVillesMutation();

  const [opened, setOpened] = useState(false);
  const [query, setQuery] = useState("");
  const [itemEdited, setItemEdited] = useState(null);

  const [addLoading, setAddLoadin] = useState(false);
  const [editLoading, setEditLoadin] = useState(false);

  const [form, setForm] = useState({});

  const handleChange = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const handleDelete = async (id) => {
    try {
      await deleteVilles(id);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleEdit = async () => {
    setEditLoadin(true);
    try {
      await editVilles({
        body: { ...form, id: itemEdited?.id },
      });
      setOpened(false);
      setEditLoadin(false);
      setItemEdited(null);
      //   console.log({ res });
    } catch (error) {
      console.log({ error });
      setEditLoadin(false);
      setOpened(false);
    }
  };

  const handleAdd = async (e) => {
    // return console.log({ form });
    setAddLoadin(true);
    try {
      await addVilles({
        body: { ...form },
      });
      setOpened(false);
      setAddLoadin(false);
      //   console.log({ res });
    } catch (error) {
      console.log({ error });
      setAddLoadin(false);
      setOpened(false);
    }
  };

  //   console.log({ data });

  return (
    <Box>
      <Text size={20}>MatierePage</Text>
      <Flex position={"apart"}>
        <InputText
          placeholder={"Rechercher un niveaux"}
          onChange={(value) => setQuery(value.toLowerCase())}
          value={query}
        />
        <Button
          onClick={() => {
            setForm(null);
            setOpened(true);
          }}
        >
          Ajouter
        </Button>
      </Flex>
      <Divider my={20} />

      <Box wihtBorder style={{ overflowY: "auto" }}>
        {isLoading ? (
          "Loading...."
        ) : (
          <table style={{ width: "100%" }}>
            <thead>
              <th>Nom</th>
              <th style={{ textAlign: "end" }}>actions</th>
            </thead>

            <tbody>
              {data?.data
                .filter((item) => item?.name.toLowerCase().includes(query))
                .map((item) => (
                  <tr
                    key={item?.id}
                    style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
                  >
                    <td>
                      <Text>{item?.name}</Text>
                    </td>
                    <td>
                      <Flex gap={15} position={"end"}>
                        <Pencil
                          size={16}
                          onClick={() => {
                            setForm(null);
                            setOpened(true);
                            setItemEdited(item);
                            setForm({
                              ...form,
                              name: item?.name,
                            });
                          }}
                        />
                        <Trash
                          size={16}
                          onClick={() => handleDelete(item?.id)}
                          color="red"
                        />
                      </Flex>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </Box>

      {/* My modal */}
      <Modal
        opened={opened}
        onClosed={() => setOpened(false)}
        title={itemEdited ? "Edit niveaux" : "Add niveaux"}
      >
        <form>
          <InputText
            label={"Nom du niveau"}
            placeholder={"niveau name"}
            onChange={(e) => handleChange(e, "name")}
            value={form?.name ?? itemEdited?.name}
          />

          <Divider my={15} />
          <Flex position={"end"} gap={15}>
            <Button
              bg={"transparent"}
              color={"gray"}
              onClick={() => setOpened(false)}
            >
              Annuler
            </Button>
            <Button
              type={"submit"}
              onClick={itemEdited ? handleEdit : handleAdd}
              isLoading={addLoading || editLoading}
            >
              {itemEdited ? "Modifier" : "Ajouter"}
            </Button>
          </Flex>
        </form>
      </Modal>
    </Box>
  );
};

export default VillePage;
