import React from "react";
import { useParams } from "react-router-dom";
import {
  useDesacteferCoachFichesMutation,
  useGetFicheWithCoachsQuery,
} from "../../../redux/features/ficheApi";
// import { useGetCoachsQuery } from "../../../redux/features/coachApi";
import useTableWithPagination from "../../../layouts/table";
import { Title } from "@mantine/core";
import { useGetVillesQuery } from "../../../redux/features/villeApi";
import toast from "react-hot-toast";

function ListeCoachsFiche() {
  const params = useParams();
  // const { data, isLoading } = useGetFicheQuery(params?.id);

  const { data: villes } = useGetVillesQuery();

  const [Unarchive] = useDesacteferCoachFichesMutation();

  const handleDelete = async (id, item) => {
    // return console.log({
    //   coachId: id,
    //   ficheId: parseInt(params?.id),
    // });
    try {
      const res = await Unarchive({
        coachId: item?.coach?.id,
        ficheId: parseInt(params?.id),
      });

      console.log(res);
      if (!res?.error) {
        return toast.success("Fiche desarchiver");
      }
    } catch (error) {
      toast.error("Une error c'est produite");
    }
  };

  const { TableWithPagination: CoachTable } = useTableWithPagination({
    useQuery: useGetFicheWithCoachsQuery,
    filterRequestQuery: { ficheId: params?.id },
    searchs: ["coach.fullName", "coach.phone"],
    componentOptions: {
      fields: [
        { label: "id", field: "id" },
        { label: "Prenom et nom", field: "coach.fullName" },
        { label: "Telephone", field: "coach.phone" },
      ],

      handleDelete: handleDelete,
    },
  });

  return (
    <div>
      <Title>Liste des coachs assiger a cette fiche</Title>
      {CoachTable}
    </div>
  );
}

export default ListeCoachsFiche;
