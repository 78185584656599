import {
  createStyles,
  Group,
  Paper,
  Text,
  ThemeIcon,
  SimpleGrid,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { User } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
  root: {
    // padding: `calc(${theme.spacing.xl} * 1.5)`,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

export function StatsSecond({ data }) {
  const { classes } = useStyles();
  const navigation = useNavigate()


  const navigateToPath = (to) => {
    navigation(`/admin/${to.toLowerCase()}`);
  };


  const stats = data.map((stat, index) => {
    return (
      <Paper withBorder p="md" radius="md" key={index} 
        style={{cursor:"pointer"}}
        onClick={()=>navigateToPath(stat?.path)}
      >
        <Group position="apart">
          <div>
            <Text
              c="dimmed"
              tt="uppercase"
              fw={700}
              fz="xs"
              className={classes.label}
            >
              {stat.description}
            </Text>
            <Text fw={700} fz="xl" mt={10}>
              {stat.value}
            </Text>
          </div>
          <ThemeIcon
            color="gray"
            variant="light"
            sx={(theme) => ({
              // color: stat.diff > 0 ? theme.colors.teal[6] : theme.colors.red[6],
            })}
            size={38}
            radius="md"
          >
            <User size="1.8rem" />
          </ThemeIcon>
        </Group>
        {/* <Text c="dimmed" fz="sm" mt="md">
          {stat?.description}
        </Text> */}
      </Paper>
    );
  });

  return (
    <div className={classes.root}>
      <SimpleGrid
        cols={4}
        breakpoints={[
          { maxWidth: "lg", cols: 3 },
          { maxWidth: "md", cols: 2 },
          { maxWidth: "sm", cols: 2 },
          { maxWidth: "xs", cols: 1 },
        ]}
      >
        {stats}
      </SimpleGrid>
    </div>
  );
}
