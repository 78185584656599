import React from "react";
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  MultiSelect,
  NumberInput,
  Paper,
  Select,
  Switch,
  Textarea,
  TextInput,
} from "@mantine/core";
import { X } from "tabler-icons-react";
import { IconTrash } from "@tabler/icons-react";
import { nanoid } from "@reduxjs/toolkit";

function TSQ({ index, form, consigne }) {
  // console.log({ form: form?.values });

  const fieldsChoices = (__index) =>
    form.values?.epreuves[index]?.correction_dp[__index]?.responses.map(
      (item, _index) => (
        <div className="" key={item.id}>
          <Group mt="xs">
            <TextInput
              placeholder="mettre une reponse"
              withAsterisk
              style={{ flex: 1 }}
              // label={`choix ${_index + 1}`}
              // key={form.key(`epreuves.${index}.correction_dp.${_index}.tire`)}
              {...form.getInputProps(
                `epreuves.${index}.correction_dp.${__index}.responses.${_index}.titre`
              )}
            />
            <Switch
              // label="Active"
              // key={form.key(
              //   `epreuves.${index}.correction_dp.${__index}.responses${_index}.isCorrect`
              // )}
              {...form.getInputProps(
                `epreuves.${index}.correction_dp.${__index}.responses.${_index}.isCorrect`,
                {
                  type: "checkbox",
                }
              )}
            />
            <ActionIcon
              color="red"
              onClick={() =>
                form.removeListItem(
                  `epreuves.${index}.correction_dp.${__index}.responses`,
                  _index
                )
              }
            >
              <IconTrash size="1rem" />
            </ActionIcon>
          </Group>
        </div>
      )
    );

  const fields = form.values?.epreuves[index]?.correction_dp.map(
    (item, _index) => (
      <div key={item.id} className="">
        <Group mt="xs">
          <TextInput
            placeholder="mettre la question"
            withAsterisk
            style={{ flex: 1 }}
            label={`Question ${_index + 1}`}
            // key={form.key(`epreuves.${index}.correction_dp.${_index}.tire`)}
            {...form.getInputProps(
              `epreuves.${index}.correction_dp.${_index}.titre`
            )}
          />
          {/* <Switch
            label="Active"
            // key={form.key(`epreuves.${index}.correction_dp.${_index}.isCorrect`)}
            {...form.getInputProps(
              `epreuves.${index}.correction_dp.${_index}.isCorrect`,
              {
                type: "checkbox",
              }
            )}
          /> */}
          <ActionIcon
            color="red"
            onClick={() =>
              form.removeListItem(`epreuves.${index}.correction_dp`, _index)
            }
          >
            <IconTrash size="1rem" />
          </ActionIcon>
        </Group>
        <Box mx={20}>
          {fieldsChoices(_index)}
          <Group justify="center" mt="md">
            <Button
              variant="subtle"
              onClick={() =>
                form.insertListItem(
                  `epreuves.${index}.correction_dp.${_index}.responses`,
                  { titre: "", isCorrect: false, id: nanoid() }
                )
              }
            >
              Ajout un choix
            </Button>
          </Group>
        </Box>
      </div>
    )
  );

  // console.log({fields});

  return (
    <Paper mb={20} p={10} shadow="xs">
      <Flex noWrap gap={5}>
        <Box style={{ flex: 1 }}>
          <TextInput
            {...form.getInputProps(`epreuves.${index}.titre`)}
            placeholder="Mettre le titre"
            mb={10}
          />
          <Textarea
            {...form.getInputProps(`epreuves.${index}.consigne.${0}`)}
            placeholder="Mettre l'enonce"
            mb={10}
            rows={6}
          />
          <Box
            p={4}
            bg={"#9991"}
            style={{ borderRadius: 10, padding: 10, marginb: "10px" }}
          >
            {fields}
          </Box>
          <Group justify="center" mt="md">
            <Button
              variant="light"
              onClick={() =>
                form.insertListItem(`epreuves.${index}.correction_dp`, {
                  titre: "",
                  id: nanoid(),
                  responses: [{ titre: "", isCorrect: false, id: nanoid() }],
                })
              }
            >
              Ajout de question
            </Button>
          </Group>
        </Box>
        <ActionIcon
          color="red"
          variant="filled"
          onClick={() => form.removeListItem(`epreuves`, index)}
        >
          <X size="1rem" />
        </ActionIcon>
      </Flex>
    </Paper>
  );
}

export default TSQ;
