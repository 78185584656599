import { Card, Text, Badge, Button, Group, Grid, Flex } from '@mantine/core';

function MessageItem({parent,message}) {
  return (
    <Grid.Col span={4}>
      <Card mih={200} shadow="sm" padding="lg" radius="md" withBorder>

        <Flex justify="space-between" mt="md" mb="xs">
          <Text fw={500}>{parent.fullName}</Text>
          {/* <Text fw={500}>{parent.phone}</Text> */}
          <Badge color="teal" size="lg">{parent.phone}</Badge>
        </Flex>

        <Text size="sm" c="dimmed">
          {message }
        </Text>
      </Card>
    </Grid.Col>
  );
}

export default MessageItem;