import { Navigate, Outlet } from "react-router-dom";
import getCurrentUser from "../hooks/getCurrentUser";
import PartenaireTemplate from "../layouts/PartenaireTemplate";

const ProtectedRoutes = () => {
  const user = getCurrentUser();
  // console.log("llalalal");
  // console.log({ user });
  return user?.status ? (
    user.status === "PARTENAIRE" ? (
      <PartenaireTemplate>
        <Outlet />
      </PartenaireTemplate>
    ) : (
      <Navigate to="/admin" replace />
    )
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ProtectedRoutes;
