import React from "react";
import styles from "../assets/styles.module.css";
import Text from "./Text";

function InputSelect({
  placeholder,
  onChange,
  label,
  style,
  mt,
  mb,
  w,
  selectWidth,
  data = [],
  value = "",
}) {
  return (
    <div className={selectWidth} style={{ marginTop: mt, marginBottom: mb }}>
      {label && <Text mb={5}>{label}</Text>}

      <select
        // className={styles.inputSelect}
        className={"form-control"}
        style={{ ...style, width: "100%" }}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder ?? "selectionner"}
        defaultValue={value}
      >
        <option value={""}>{placeholder ?? "selectionner"}</option>
        {data.map((item, index) => (
          <option key={index} value={item?.value}>
            {item?.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default InputSelect;
