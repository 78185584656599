import React, { useState } from "react";
import Box from "../../components/Box";
import Flex from "../../components/Flex";
import Text from "../../components/Text";
import InputText from "../../components/InputText";
import Button from "../../components/Button";
import Divider from "../../components/Divider";
// import { Eye, Pencil, Trash } from "tabler-icons-react";
import Modal from "../../components/Modal";
import InputSelect from "../../components/InputSelect";
import { useGetVillesQuery } from "../../redux/features/villeApi";
import {
  useAddParentsMutation,
  useDeleteParentsMutation,
  useEditParentsMutation,
  useGetParentsQuery,
} from "../../redux/features/parentApi";
import useTableWithPagination from "../../layouts/table";
import { Group, TextInput, Loader } from "@mantine/core";
import DetailsParent from "../prospects/DetailsParent";
import toast from "react-hot-toast";
import Papa from "papaparse";
// import useTableWithPagination, {
//   TableWithPagination,
// } from "../../layouts/table/useTableWithPagination";

const ParentPage = () => {
  const { data: parentsData, isLoading } = useGetParentsQuery();
  const { data: villes } = useGetVillesQuery();
  const [addParents] = useAddParentsMutation();
  const [editParents] = useEditParentsMutation();
  const [deleteParents] = useDeleteParentsMutation();

  const [opened, setOpened] = useState(false);
  // const [query, setQuery] = useState("");
  const [itemEdited, setItemEdited] = useState(null);

  const [addLoading, setAddLoadin] = useState(false);
  const [editLoading, setEditLoadin] = useState(false);

  const [form, setForm] = useState({});
  const [search, setSearch] = useState("");

  const handleChange = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  // console.log({ data });

  const handleDelete = async (id) => {
    try {
      await deleteParents(id);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleEdit = async () => {
    setEditLoadin(true);
    try {
      await editParents({
        body: { ...form, id: itemEdited?.id },
      });
      setOpened(false);
      setEditLoadin(false);
      setItemEdited(null);
      //   console.log({ res });
    } catch (error) {
      console.log({ error });
      setEditLoadin(false);
      setOpened(false);
    }
  };

  const handleAdd = async (e) => {
    // return console.log({ form });
    setAddLoadin(true);
    try {
      await addParents({
        body: { ...form },
      });
      setOpened(false);
      setAddLoadin(false);
      //   console.log({ res });
    } catch (error) {
      console.log({ error });
      setAddLoadin(false);
      setOpened(false);
    }
  };

  // console.log({ villes });

  const { TableWithPagination: ParentTable } = useTableWithPagination({
    useQuery: useGetParentsQuery,
    searchs: ["fullName", "adresse"],
    searchValue: search,
    filters: [
      {
        data: villes ? villes?.data?.formatArray() : [],
        label: "Ville",
        key: "villeId",
      },
    ],
    componentOptions: {
      fields: [
        { label: "id", field: "id" },
        { label: "Prenom et nom", field: "fullName" },
        { label: "Telephone", field: "phone" },
        { label: "Adresse", field: "adresse" },
        { label: "Ville", field: "ville.name" },
        { label: "Provenance", field: "provenance" },
        { label: "Nombre enfants", field: "countEnfant" },
      ],
      handleAdd: () => {
        setItemEdited(null);
        setForm(null);
        setOpened(true);
      },
      handleEdit: (id, item) => {
        setForm(null);
        setOpened(true);
        setItemEdited(item);
        setForm({
          ...form,
          name: item?.fullName,
          villeId: item?.villeId,
          adresse: item?.adresse,
          phone: item?.phone,
        });
      },
      handleDelete: async (id) => {
        handleDelete(id);
      },
      otherAction: (id, item) => <DetailsParent parent={{ id: id, fullName: item?.fullName }} />
    },
  });

  const exportToCSV = () => {
    if (!parentsData || !parentsData.data || parentsData.data.length === 0) {
      toast.error("Aucune donnée à exporter");
      return;
    }

    // Accédez aux données dans elevesData
    const formattedData = parentsData.data.map(parent => ({
      name: parent.fullName || 'Inconnu',
      ville: parent.ville?.name || 'Inconnu',
      adresse: parent.adresse || 'Inconnu',
      provenance: parent.provenance || 'Inconnu',
      nombre_enfants: parent.countEnfant ,
      // Ajoutez d'autres champs ici si nécessaire
    }));

    try {
      const BOM = "\uFEFF";
      const csvData = Papa.unparse(formattedData);
      const blob = new Blob([BOM + csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "parents.csv");
      link.click();
    } catch (error) {
      console.error("Erreur lors de l'exportation en CSV", error);
      toast.error("Erreur lors de l'exportation en CSV");
    }
  };

  return (
    <Box>
      <Text size={20}>La liste des parents</Text>
      <Group position="apart" mt={10}>
        <TextInput
          placeholder={"Rechercher un parent"}
          onChange={(value) => setSearch(value.target.value.toLowerCase())}
          value={search}
          style={{ flex: 1 }}
        />
        <Button title="Ajouter" onClick={() => setOpened(true)}>
          Ajouter
        </Button>
        <div className="button-container mx-2">
          <Button onClick={exportToCSV}
            disabled={isLoading}
            className="button-with-loader"
          >
            {isLoading && <Loader type="bar" className="loader" />}
            Exporter en CSV</Button>
        </div>
      </Group>
     

      <ParentTable />

      {/* My modal */}
      <Modal
        opened={opened}
        onClosed={() => setOpened(false)}
        title={itemEdited ? "Edit niveaux" : "Add niveaux"}
      >
        <form>
          <InputText
            label={"Nom du parent"}
            placeholder={"nom ici"}
            onChange={(e) => handleChange(e, "fullName")}
            value={form?.fullName ?? itemEdited?.fullName}
          />

          <InputText
            label={"Address"}
            placeholder={"address name"}
            onChange={(e) => handleChange(e, "adresse")}
            value={form?.adresse ?? itemEdited?.adresse}
          />

          <InputText
            type="number"
            label={"telephone"}
            placeholder={"77 *** ** **"}
            onChange={(e) => handleChange(e, "phone")}
            value={form?.phone ?? itemEdited?.phone}
          />

          <InputSelect
            mt={15}
            label={"Selectionner une ville"}
            data={
              villes?.data.map((item) => ({
                value: item?.id,
                label: item?.name,
              })) ?? []
            }
            placeholder={"Selectionner"}
            onChange={(e) => handleChange(parseInt(e), "villeId")}
            value={form?.villeId ?? itemEdited?.villeId}
          />

          <Divider my={15} />
          <Flex position={"end"} gap={15}>
            <Button
              bg={"transparent"}
              color={"gray"}
              onClick={() => setOpened(false)}
            >
              Annuler
            </Button>
            <Button
              type={"submit"}
              onClick={itemEdited ? handleEdit : handleAdd}
              isLoading={addLoading || editLoading}
            >
              {itemEdited ? "Modifier" : "Ajouter"}
            </Button>
          </Flex>
        </form>
      </Modal>
    </Box>
  );
};

export default ParentPage;
