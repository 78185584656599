import React from "react";
import { useGetPayementsParentsQuery } from "../../redux/features/payementApi";
import { Button, Center, Group, Loader, Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import PayementParentTable from "./components/PayementParentTable";

const PaiementParentPage = () => {
  const { data, isLoading } = useGetPayementsParentsQuery();

  const [opened, { close }] = useDisclosure();

  console.log({ data });

  return (
    <div>
      <Group position="apart" mb={20}>
        <Title order={3}>Paiements des parents </Title>
        {/* <Button>Ajouter un paiement</Button> */}
      </Group>
      {isLoading ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <PayementParentTable data={data ? data?.results : []} />
      )}

      <Modal opened={opened} onClose={close}>
        <form></form>
      </Modal>
    </div>
  );
};

export default PaiementParentPage;
