import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const appelApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getAppels: build.query({
      query: () => `m1/appels/`,
      // query: ({ limit }) =>
      //   limit ? `appels/?limit=${limit}&offset=0` : "appels/",
      providesTags: ["Appels"],
    }),
    getStudentAppels: build.query({
      query: (id) => `m1/appels/students/${id}/`,
      // query: ({ limit }) =>
      //   limit ? `appels/?limit=${limit}&offset=0` : "appels/",
      providesTags: ["Appels"],
    }),
    getAppelsWeekly: build.query({
      query: ({ date_start, date_end, date, coachId=203 }) =>
          date
              ? `m1/appels/?date=${date}${coachId ? `&coachId=${coachId}` : ''}`
              : date_start && date_end
              ? `m1/appels/?start_date=${date_start}&end_date=${date_end}${coachId ? `&coachId=${coachId}` : ''}`
              : date_start
              ? `m1/appels/?start_date=${date_start}${coachId ? `&coachId=${coachId}` : ''}`
              : `m1/appels/${coachId ? `?coachId=${coachId}` : ''}`,
      providesTags: ["Appels"],
  }),  
    addAppels: build.mutation({
      query({ body, token }) {
        return {
          url: API.APPELS_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Appels"],
    }),
    EditAppels: build.mutation({
      query({ body, token }) {
        return {
          url: API.APPELS_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Appels"],
    }),
    deleteAppels: build.mutation({
      query(id) {
        return {
          url: API.APPELS_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Appels"],
    }),
  }),
});

export const {
  useGetAppelsQuery,
  useGetAppelsWeeklyQuery,
  useAddAppelsMutation,
  useEditAppelsMutation,
  useDeleteAppelsMutation,
  useGetStudentAppelsQuery,
} = appelApi;
