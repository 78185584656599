import React from "react";
import { NavLink } from "react-router-dom";
import { partenaireSidebarLinks } from "../routes/sidebarNavigation";
import Divider from "./Divider";
import Flex from "./Flex";
import useCurrentUser from "../hooks/getCurrentUser";
import { Group, Text } from "@mantine/core";
import { Logout } from "tabler-icons-react";
import { useDispatch } from "react-redux";
import { clearUserInfo } from "../redux/features/userSlice";
import { clearCurrentUser } from "../redux/reducers/userSlice";

function PartenaireSideBar({ openedMenu }) {
  const user = useCurrentUser();

  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(clearCurrentUser());
    dispatch(clearUserInfo());
  };

  return (
    <div
      className="h-100 py-2 sidebar-container-self"
      style={{ backgroundColor: "#350000", overflowY: "scroll" }}
    >
      {!openedMenu ? (
        <>
          <h1 className="fs-5 text-light px-4 fw-bold">GENIMI BACKOFFICE</h1>
          <Text className="px-4" size="xs">
            {user.fullName}
          </Text>
        </>
      ) : (
        <h1 className="fs-7 text-light px-3 fw-bold mt-0">G</h1>
      )}
      <nav className="py-0 mt-4">
        <ul className="d-flex flex-column p-0 px-2">
          {partenaireSidebarLinks.map((section, index) => (
            <span key={index}>
              {section?.links.map((item) => (
                <NavLink
                  key={item.to}
                  className="nav-link text-light mt-0 border-bottom-0 d-flex align-items-center p-2  rounded "
                  to={"/partenaire/" + item.to}
                  // end
                  style={{
                    height: 35,
                    marginBottom: 5,
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                  }}
                >
                  {({ isActive, isPending }) => (
                    <>
                      <Flex>
                        {item?.icon && (
                          <item.icon style={{ marginRight: 5 }} size={23} />
                        )}
                        {!openedMenu && (
                          <span className="d-none d-sm-block mt-2 fs-6 ">
                            {item?.label}
                          </span>
                        )}
                      </Flex>
                    </>
                  )}
                </NavLink>
              ))}
              {partenaireSidebarLinks.length === index + 1 ? null : (
                <Divider my={10} />
              )}
            </span>
          ))}
          <Divider my={10} />
          <Group
            className="nav-link cursor-pointer text-light mt-0 border-bottom-0 d-flex align-items-center p-2  rounded "
            style={{
              height: 35,
              marginBottom: 5,
            }}
            onClick={handleLogOut}
          >
            <Logout />
            <span className="d-none d-sm-block mt-2 fs-6 ">Se deconnecter</span>
          </Group>
        </ul>
      </nav>
    </div>
  );
}

export default PartenaireSideBar;
