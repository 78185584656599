export const LOGIN = "login";
export const FORGETPASSWORD = "auth/forgetPassword";
export const RESETPASSWORD = "auth/resetPassword";

export const NOTFOUND = "404";

export const ADMIN_HOME = "/admin";
export const ACCEUIL = "acceuil";
//  export const COMPTERENDU = "compteRendu";
export const CLASSES_PATH = "class";
export const FICHES_PATH = "fiches";
export const MATIERES_PATH = "matieres";
export const NIVEAUX_PATH = "niveaux";
export const VILLES_PATH = "villes";
export const DIAGNOSTIQUES_PATH = "diagnostiques";
export const DIAGNOSTIQUES_PARENT_PATH = "diagnostiques/parents";
export const DIAGNOSTIQUES_PARENT_QUIZZ_PATH = "diagnostiques/parents/quizz";
export const APPELS_PATH = "appels";
export const PAIEMENTS_PATH = "paiements";
export const ECOLES_PATH = "ecoles";
export const COACHS_PATH = "coachs";
export const PARENTS_PATH = "parents";
export const PROSPECTS_PATH = "prospects";
export const ELEVES_PATH = "eleves";
export const BULLETIN_PATH = "bulletin";
export const COMPOSITION_PATH = "composition";

// Partenaire
export const PARTENAIRE_PATH = "acceuil";
