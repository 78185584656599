function AddAction(mutationName, data) {
  return new Promise(async (resolve, reject) => {

    try {
      const res = await mutationName({ body: data });
      resolve(res)
    } catch (error) {
      reject(error)
    }
  })
}

export default AddAction;
