import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const matiereApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getMatiere: build.query({
      query: () => API.MATIERES_API.GETALL(),
      providesTags: ["Matieres"],
    }),
    addMatiere: build.mutation({
      query({ body, token }) {
        return {
          url: API.MATIERES_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Matieres"],
    }),
    EditMatiere: build.mutation({
      query({ body, token }) {
        return {
          url: API.MATIERES_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Matieres"],
    }),
    deleteMatieres: build.mutation({
      query(id) {
        return {
          url: API.MATIERES_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Matieres"],
    }),
  }),
});

export const {
  useGetMatiereQuery,
  useAddMatiereMutation,
  useEditMatiereMutation,
  useDeleteMatieresMutation,
} = matiereApi;
