import React, { useState } from "react";
import {
  useAddParrainsMutation,
  useDeleteParrainsMutation,
  useEditParrainsMutation,
  useGetParrainsQuery,
} from "../../../redux/features/parrainApi";
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Modal,
  NumberInput,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import EditAction from "../actions/EditAction";
import AddAction from "../actions/AddAction";
import DeleteAction from "../actions/DeleteAction";
import { useSearchParams } from "react-router-dom";
import useTableWithPagination from "../../../layouts/table";

function ParrainPage() {
  const [add, { isLoading: addLoading }] = useAddParrainsMutation();
  const [edit, { isLoading: editLoading }] = useEditParrainsMutation();
  const [remove] = useDeleteParrainsMutation();

  const [opened, { open, close }] = useDisclosure(false);
  const [itemEdited, setItemEdited] = useState(null);

  const [search, setSearch] = useState("");

  const [queryParams] = useSearchParams({ status: "" });

  const status = queryParams.get("status");

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      bourses: "",
      status: "",
    },

    validate: {
      firstName: (value) => (value === "" ? "Ce champ est obligatoire" : null),
      lastName: (value) => (value === "" ? "Ce champ est obligatoire" : null),
      phone: (value) => (value === "" ? "Ce champ est obligatoire" : null),
      bourses: (value) => (value === "" ? "Ce champ est obligatoire" : null),
      //   schoolId: (value) => (value === "" ? "Ce champ est obligatoire" : null),
    },
  });

  const { TableWithPagination: ParrainTable } = useTableWithPagination({
    useQuery: useGetParrainsQuery,
    searchs: ["firstName", "lastName", "phone"],
    searchValue: search,
    filters: [
      {
        data: ["perdu", "actif"],
        label: "status",
        key: "status",
        query: status.toLowerCase(),
      },
    ],
    queryParams: status,
    componentOptions: {
      fields: [
        { label: "id", field: "id" },
        { label: "Prenom", field: "firstName" },
        { label: "Nom", field: "lastName" },
        { label: "Telephone", field: "phone" },
        { label: "bourses donnees", field: "bourses_donnees" },
        { label: "bourses restants", field: "bourses_restantes" },
        { label: "status", field: "status" },
      ],
      handleAdd: open,
      handleEdit: (id, item) => {
        setItemEdited(id);
        form.setValues({ ...item });
        open();
      },
      handleDelete: async (id) => await DeleteAction(remove, id),
    },
  });

  return (
    <Box>
      <Group position="apart" mt={10}>
        <TextInput
          placeholder={"Rechercher un parrain"}
          onChange={(value) => setSearch(value.target.value.toLowerCase())}
          value={search}
          style={{ flex: 1 }}
        />
        <Button title="Ajouter" onClick={open}>
          Ajouter
        </Button>
      </Group>

      <ParrainTable />

      {/* My modal */}
      <Modal
        opened={opened}
        onClose={() => {
          close();
          form.reset();
        }}
        title={itemEdited ? "Modifier le parrain" : "Ajouter un parrain"}
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            if (itemEdited) {
              await EditAction(edit, values);
            } else {
              await AddAction(add, values);
            }
            close();
            form.reset();
          })}
        >
          <TextInput
            label={"Prenom"}
            placeholder={"Paull"}
            {...form.getInputProps("firstName")}
            mb={10}
          />
          <TextInput
            label={"Nom"}
            placeholder={"Ba"}
            {...form.getInputProps("lastName")}
            mb={10}
          />

          <TextInput
            label={"Telephone"}
            placeholder={"78 3356 65 75"}
            {...form.getInputProps("phone")}
          />

          <NumberInput
            label={"Bourses"}
            placeholder={"Nombre de bourses"}
            {...form.getInputProps("bourses")}
            min={0}
          />
          <Select
            {...form.getInputProps("status")}
            placeholder="Status du parrain"
            label={"Status"}
            data={["ACTIF", "PERDU"]}
            mt={15}
            searchable
          />

          <Divider my={15} />
          <Flex position={"end"} gap={15}>
            <Button color={"gray"} onClick={close}>
              Annuler
            </Button>
            <Button type={"submit"} loading={addLoading || editLoading}>
              {itemEdited ? "Modifier" : "Ajouter"}
            </Button>
          </Flex>
        </form>
      </Modal>
    </Box>
  );
}

export default ParrainPage;
