import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NOTFOUND } from "../utils/navigationPaths";
import {
  privatePartenaireRoutes,
  privateRoutes,
  publicRoutes,
} from "./app.routes";
import ProtectedRoutes from "./protectedRoutes";
import UnProtectedRoutes from "./unProtectedRoutes";
import ProtectedPartenaireRoutes from "./protectedPartenaireRoutes";

const recursiveRoutes = (routes) => {
  return (
    <>
      {routes.map((route, index) => {
        if (route.subPath) {
          return (
            <Route path={route.path} element={route.element} key={index}>
              {recursiveRoutes(route.subPath)}
            </Route>
          );
        }

        return <Route path={route.path} element={route.element} key={index} />;
      })}
    </>
  );
};

function Roots() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<UnProtectedRoutes />}>
          {recursiveRoutes(publicRoutes)}
        </Route>

        <Route path="/partenaire" element={<ProtectedPartenaireRoutes />}>
          {recursiveRoutes(privatePartenaireRoutes)}
        </Route>

        <Route path="/admin" element={<ProtectedRoutes />}>
          {recursiveRoutes(privateRoutes)}
        </Route>

        <Route path={NOTFOUND} element={<div>Page 404</div>} />
      </Routes>
    </BrowserRouter>
  );
}

export default Roots;
