import { useState } from "react";
import {
  Table,
  ScrollArea,
  Group,
  Text,
  ActionIcon,
  Badge,
} from "@mantine/core";
import { Edit, Eye, Trash } from "tabler-icons-react";
import { sortData } from "../../../utils/tableActions";
import Th from "../../../components/Th";
import { useNavigate } from "react-router-dom";

export default function DiagnostiqueTable({ data, handleDelete }) {
  // const [search, setSearch] = useState("");
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const navigation = useNavigate();

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search: "" }));
  };

  // console.log({ sortedData, data });

  // const handleSearchChange = (event) => {
  //   const { value } = event.currentTarget;
  //   setSearch(value);
  //   setSortedData(
  //     data.filter((item) =>
  //       item.title.toLowerCase().includes(value.toLowerCase())
  //     )
  //   );
  // };

  const rows = sortedData.map((row) => (
    <tr key={row?.id}>
      <td>
        <Group noWrap>
          <Badge size="sm">{row?.title}</Badge>
          {/* <Text weight={600} style={{ whiteSpace: "nowrap" }}>
            {row?.title}
          </Text> */}
        </Group>
      </td>
      <td>
        <Text weight={600} size={17}>
          {row?.nombreDeNiveaux}
        </Text>
      </td>
      <td>{row?.type_diagnostique}</td>
      <td>
        <Group position="right">
          <ActionIcon
            color="blue"
            onClick={() => navigation(row.title, { 
                  state: {id:row.id,title:row.title }
                }
              )
            }
          >
            <Eye size={15} />
          </ActionIcon>
          {/* <ActionIcon color="green">
            <Edit size={15} />
          </ActionIcon> */}
          <ActionIcon onClick={() => handleDelete(row.id)}>
            <Trash size={15} color="red" />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <ScrollArea>
      {/* <TextInput
        placeholder="Search by any field"
        mb="md"
        icon={<Search size="1rem" />}
        value={search}
        onChange={handleSearchChange}
      /> */}
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw={700}
        sx={{ tableLayout: "fixed" }}
        bg={"#fff"}
      >
        <thead>
          <tr>
            <Th
              sorted={sortBy === "firstName"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("firstName")}
            >
              Diagnostics
            </Th>
            <Th
              sorted={sortBy === "nombreDeNiveaux"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("nombreDeNiveaux")}
            >
              Nombre de niveaux
            </Th>
            <Th
              sorted={sortBy === "Type diagnostique"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("TypeDiagnostique")}
            >
              Type diagnostique
            </Th>
            <th style={{ textAlign: "right" }}>action</th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              {/* <td colSpan={Object.keys(data[0]).length}> */}
              <Text weight={500} align="center">
                Nothing found
              </Text>
              {/* </td> */}
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}
