import { createSlice } from "@reduxjs/toolkit/";

const initialState = {
  currentUser: null,
};

const autSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    clearCurrentUser: (state) => {
      state.currentUser = null;
    },
  },
});

export const { setCurrentUser, clearCurrentUser } = autSlice.actions;
export default autSlice.reducer;
