import { useMemo, useState } from "react";
import {
  Table,
  ScrollArea,
  Group,
  Text,
  ActionIcon,
  Button,
} from "@mantine/core";
import { Edit } from "tabler-icons-react";
import { sortData } from "../../../utils/tableActions";
import Th from "../../../components/Th";

export default function CompositionTable({ data, handleEdit }) {
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search: "" }));
  };

  useMemo(() => {
    setSortedData(data);
  }, [data]);

  const rows = sortedData.map((row) => (
    <tr key={row?.student?.id}>
      <td>
        <Group noWrap>
          <Text weight={600} style={{ whiteSpace: "nowrap" }}>
            {row?.student?.fullName}
          </Text>
        </Group>
      </td>
      <td>
        <Group noWrap>
          <Text weight={500} style={{ whiteSpace: "nowrap" }}>
            {row?.student?.school}
          </Text>
        </Group>
      </td>
      <td>
        <Group noWrap position="center">
          {!row?.compositions?.COMPOSITION1?.moyenne ? ( //   ajouter // <Button size="xs" radius={10}>
            // </Button>
            <Text>Pas de note</Text>
          ) : (
            <Text weight={600} style={{ whiteSpace: "nowrap" }} color="green">
              {row?.compositions?.COMPOSITION1?.moyenne}
            </Text>
          )}
        </Group>
      </td>
      <td>
        <Group noWrap position="center">
          {!row?.compositions?.COMPOSITION2?.moyenne ? (
            // <Button size="xs" radius={10}>
            //   ajouter
            // </Button>
            <Text>Pas de note</Text>
          ) : (
            <Text weight={600} style={{ whiteSpace: "nowrap" }} color="green">
              {row?.compositions?.COMPOSITION2?.moyenne}
            </Text>
          )}
        </Group>
      </td>
      <td>
        <Group noWrap position="center">
          {!row?.compositions?.COMPOSTION3?.moyenne ? (
            // <Button size="xs" radius={10}>
            //   ajouter
            // </Button>
            <Text>Pas de note</Text>
          ) : (
            <Text weight={600} style={{ whiteSpace: "nowrap" }} color="green">
              {row?.compositions?.COMPOSTION3?.moyenne}
            </Text>
          )}
        </Group>
      </td>
      {/* <td>
        <Group position="right">
          <ActionIcon color="blue" onClick={() => handleEdit(row.id, row)}>
            <Edit size={15} />
          </ActionIcon>
        </Group>
      </td> */}
    </tr>
  ));

  return (
    <ScrollArea>
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw={700}
        sx={{ tableLayout: "fixed" }}
        bg={"#fff"}
      >
        <thead>
          <tr>
            <Th
              sorted={sortBy === "fullName"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("fullName")}
            >
              Eleves
            </Th>
            <Th
              sorted={sortBy === "school"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("school")}
            >
              ecole
            </Th>
            <Th
              sorted={sortBy === "COMPOSITION"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("COMPOSITION")}
            >
              Composition 1
            </Th>
            <Th
              sorted={sortBy === "COMPOSITION"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("COMPOSITION")}
            >
              Composition 2
            </Th>
            <Th
              sorted={sortBy === "COMPOSITION"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("COMPOSITION")}
            >
              Composition 3
            </Th>
            {/* <th style={{ textAlign: "right" }}>action</th> */}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              {/* <td colSpan={Object.keys(data[0]).length}> */}
              <Text weight={500} align="center">
                Nothing found
              </Text>
              {/* </td> */}
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}
